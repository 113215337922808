<template>
  <div class="login">
    <p>选择职位</p>
    <van-button type="info" size="large" round @click="shouqian">售前</van-button>
    <van-button type="info" size="large" round @click="shouzg">售前主管</van-button>
    <van-button type="info" size="large" round @click="cpzg">产品主管</van-button>
    <van-button type="info" size="large" round @click="cpjl">产品经理</van-button>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    shouqian() {
      localStorage.setItem("openid", JSON.stringify('111#/'));
      this.$router.push({ path: "/explain" });
    },
    shouzg() {
      localStorage.setItem("openid", JSON.stringify('o7u0S6Kbz0P5FYNL4at2U9DZHhBk#/'));
      this.$router.push({ path: "/explain" });
    },
    cpzg() {
      localStorage.setItem("openid",JSON.stringify('o7u0S6ANHxDMmC7yeSjwJISGGkwQ#/') );
      this.$router.push({ path: "/explain" });
    },
    cpjl() {
      localStorage.setItem("openid", JSON.stringify('o7u0S6JTfdo39JnG5Dcp-LvEJpwg#/'));
      this.$router.push({ path: "/explain" });
    }
  }
};
</script>


<style lang="less" scoped>
.login {
  padding-left: 0.2rem /* 20/100 */;
  padding-right: 0.2rem /* 20/100 */;
  padding-top: 2rem /* 200/100 */;
  p {
    text-align: center;
    font-size: 0.8rem; /* 25/100 */
    margin-bottom: 0.2rem /* 20/100 */;
  }
  .van-button--round {
    margin-bottom: 0.5rem; /* 20/100 */
  }
}
</style>



