<template>
  <div class="draft-status">
    <button class="draft-submit" @click="SubmitApplication" v-if="status == 'pre_sale_draft'">提交申请</button>
    <!-- 2021/4/27  需求 草稿不允许搁置 被驳回 可以搁置 ps_id -->
    <button class="draft-submit" @click="Shelve" v-if="isshelve">搁置项目</button>
    <button class="draft-submit" @click="explain" v-if="status != 'pre_sale_draft'">补充说明</button>
    <van-cell-group v-if='showshelve'>
      <h2 class="text-title">搁置理由</h2>
      <van-field v-model="shelvetext" placeholder="请输入搁置理由" />
      <button class="transfer" @click="changeShelve">确定搁置</button>
    </van-cell-group>
    <div class="btnlist">
      <button class="draft-return" @click="clickCopy">复制到草稿</button>
      <button class="draft-transfer" @click="Transfertothers">移交他人</button>
    </div>
    <van-cell-group v-if='showTransfer'>
      <h2 class="text-title">请输入被移交人（售前）的邮箱（必填）</h2>
      <van-field v-model="mailbox" placeholder="邮箱" />
      <van-field v-model="reason" rows="3" type="textarea" placeholder="请输入移交项目的理由（非必填）" />
      <button class="transfer" @click="changeTransfer">确定移交</button>
    </van-cell-group>
    <!-- 弹出层 -->
    <van-popup v-model="show" position="bottom" round :style="{ height: '40%' }" >
      <van-picker
        title="请选择营销主管"
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="onCancel"
        @change="onChange"
      />
    </van-popup>
    <!-- 补充说明 -->
    <van-dialog v-model="supplement" title="补充说明（必填）" show-cancel-button @confirm='Submission'>
      <van-field
        v-model="value"
        rows="5"
        autosize
        type="textarea"
        placeholder="请输入文本"
        class="textarea"
      />
    </van-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      projectid: "",
      mailbox: "",
      reason: "",
      showTransfer: false,
      showshelve: false,
      shelvetext: "",
      show: false,
      columns: [],
      mdList: [],
      mdID: "",
      supplement: false,
      value: ""
    };
  },
  props: ["status", "ps_id"],
  created() {
    this.projectid = localStorage.getItem("projectid");
    this.getMarketingDirector();
    console.log(this.ps_id);
    // console.log(status !== 'pre_sale_confirm_complete' && status == 'pre_sale_draft' && !ps_id);
  },
  computed: {
    isshelve() {
      if (this.status == "pre_sale_confirm_complete") {
        return false;
      } else if (this.status == "pre_sale_draft" && this.ps_id) {
        return false;
      } else {
        return true;
      }
    }
  },
  methods: {
    // 提交申请
    async SubmitApplication() {
      this.show = true;
    },
    // 获取营销主管的列表
    async getMarketingDirector() {
      const res = await this.axios.get("api/frontend/pre_sale_supervisor/list");
      console.log(res);
      if (res.status_code === 200) {
        this.mdList = res.data;
        this.mdList.forEach(item => {
          this.columns.push(item.name);
        });
      }
    },
    // 确认选择
    async onConfirm(value, index) {
      let ids = new Array();
      ids = this.mdList.filter(item => item.name == value);
      this.mdID = ids[0].id;
      const res = await this.axios.post(
        `/api/frontend/subject/${this.projectid}/operation/submit`,
        {
          pre_sale_supervisor_id: this.mdID
        }
      );
      console.log(res);
      if (res.status_code === 200) {
        this.$toast("提交成功");
        this.$router.push({ path: "/projectlist" });
      } else {
        this.$toast(res.error);
      }
    },
    // 选择改变
    onChange(picker, value, index) {},
    // 取消选择
    onCancel() {
      this.$toast("取消");
      this.show = false;
    },
    async Transfertothers() {
      this.showTransfer = true;
      this.showshelve = false;
    },
    // 移交
    changeTransfer() {
      this.changeTransferapi();
    },
    async changeTransferapi() {
      const res = await this.axios.post(
        `/api/frontend/subject/${this.projectid}/operation/changeSubject`,
        {
          email: this.mailbox,
          explain: this.reason
        }
      );
      if (res.status_code === 200) {
        this.$toast("移交成功");
        this.$router.push({ path: "/news" });
      } else {
        this.$toast(res.error);
      }
    },
    Shelve() {
      this.showshelve = true;
      this.showTransfer = false;
    },
    changeShelve() {
      this.changeShelveapi();
    },
    async changeShelveapi() {
      const res = await this.axios.post(
        `/api/frontend/subject/${this.projectid}/operation/shelve`,
        {
          explain: this.shelvetext
        }
      );
      if (res.status_code === 200) {
        this.$router.push({ path: "/projectlist" });
      } else {
        this.$toast(res.error);
      }
    },
    // 复制项目
    async clickCopy() {
      const res = await this.axios.post(
        `/api/frontend/subject/copy/${this.projectid}`
      );
      console.log(res);
      if (res.status_code === 200) {
        this.$toast("复制成功,请到申请列表查看项目");
      } else {
        this.$toast(res.error);
      }
    },
    explain() {
      this.supplement = true;
    },
    async Submission() {
      console.log(this.value);
      const res = await this.axios.post(
        `/api/frontend/subject/replenish/${this.projectid}`,
        {
          explain: this.value
        }
      );
      if (res.status_code === 200) {
        history.go(0);
      } else {
        this.$toast(res.error);
      }
      console.log(res);
    }
  }
};
</script>


<style lang="less" scoped>
@jianbian: linear-gradient(90deg, #09aaff, #2685ff);
.draft-status {
  text-align: center;
  padding-bottom: 1.5rem;
  .draft-submit {
    width: 12.38rem /* 619/50 */;
    height: 1.7rem /* 85/50 */;
    background: @jianbian;
    border-radius: 43px;
    color: #fff;
    margin-bottom: 1rem;
  }
  .btnlist {
    overflow: hidden;
    padding-left: 1.1rem /* 29/50 */;
    padding-right: 1.1rem /* 29/50 */;
    box-sizing: border-box;
    .draft-return {
      float: left;
      width: 5rem /* 366/50 */;
      height: 1.7rem /* 85/50 */;
      background: @jianbian;
      border-radius: 43px;
      color: #fff;
    }
    .draft-transfer {
      float: right;
      width: 5rem /* 366/50 */;
      height: 1.7rem /* 85/50 */;
      background: @jianbian;
      border-radius: 43px;
      color: #fff;
    }
  }
  .text-title {
    color: #8b919a;
    text-align: left;
    padding-left: 0.5rem /* 25/50 */;
    padding-top: 0.4rem /* 20/50 */;
  }
  .transfer {
    width: 6rem /* 366/50 */;
    height: 1.7rem /* 85/50 */;
    background: @jianbian;
    border-radius: 43px;
    color: #fff;
    margin-bottom: 0.5rem; /* 20/100 */
  }
}
</style>
