<template>
  <div class="explain">
    <phoneHander></phoneHander>
    <div class="bgimage">
      <img src="../assets/img/smbanner.png" alt="">
    </div>
    <p>定制申请功能，专为用户定制提供服务，大家可以在这里新增定制，编辑项目信息，上传定制所需资料，帮助您快速定制一套客户专属的解决方案</p>
    <van-button round type="info" @click="go">新增定制</van-button>
  </div>
</template>

<script>
import phoneHander from "../components/phoneHander";
import { json } from "body-parser";
export default {
  components: {
    phoneHander
  },
  data() {
    return {
      AppID: "wx1f6f98c3a14b453c",
      REDIRECT_URI: encodeURIComponent(window.location.href),
      code: "",
      uid: "",
      openid: "",
      token: "",
      is_reviewed: ""
    };
  },
  created() {
    this.token = localStorage.getItem("token"); // 获取token
    this.openid = this.getQueryValue("openid"); // 从路径上获取openid
    if (this.token) {
      this.getuser();
    }
    if (this.openid) {
      localStorage.setItem("openid", this.openid); // 将openid存起来
      this.login(); // 登录后移除路径上的openid 页面带有openid就会进行登录 每次登录把获取的token存储起来
    } else if (!this.openid && !this.token) {
      // console.log("分享出去走这里 没有token没有openid跳注册 有token还是原来的账号不做改变");
      setTimeout(() => {
        this.$router.push({ path: "/register" });
      }, 1000);
    }
  },
  mounted() {
    // console.log(this.uid);
    // console.log(this.code);
    // if (this.code == "" || this.code == null) {
    //   // 2.uid不存在的时候 进行微信授权操作
    //   this.getCode();
    //   localStorage.setItem("uid", JSON.stringify(1)); // 3. 将 uid赋值 存起来
    // }
  },
  methods: {
    async go() {
      // this.$router.push({ path: "/basicinfo" });
      const res = await this.axios.post("api/frontend/new/template");
      if (res.status_code === 200) {
        if (this.is_reviewed == 1) {
          localStorage.setItem("projectid", res.data.id);
          this.$router.push({ path: "/CustomApplication" });
        } else {
          this.$toast("您没有该权限");
        }
      } else {
        this.$toast(res.error);
      }
      console.log(res);
    },
    getCode() {
      location =
        "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
        this.AppID +
        "&redirect_uri=" +
        this.REDIRECT_URI +
        "&response_type=code&scope=snsapi_userinfo&connect_redirect=1&state=123#wechat_redirect";
      // const res = await this.axios.post("/api/frontend/oauth");
      // console.log(res);
    },
    getQueryValue(name) {
      var url = window.location.href;
      var result = url.match(new RegExp("[?&]" + name + "=([^&]+)", "i"));

      if (result == null || result.length < 1) {
        return "";
      }
      return decodeURI(result[1]);
    },
    async login() {
      const res = await this.axios.post(
        `/api/frontend/member/login?openid=${this.openid}`
      );
      if (res.status_code === 200) {
        // 7.login成功吧token存起来
        localStorage.setItem("token", res.token);
        this.getuser();
        this.funcUrlDel("openid");
      } else if (res.status_code === 401) {
        // 8.login为401时跳转到注册页面
        this.$router.push({ path: "/register" });
      }
    },
    async getuser() {
      console.log("执行了吗");
      const res = await this.axios.get("api/frontend/member/me");
      if (res.status_code === 200) {
        this.is_reviewed = res.data.is_reviewed;
        console.log(this.is_reviewed);
        console.log(res.data);
        localStorage.setItem("role", res.data.role);
        let GETINTO = this.getQueryValue("getinto");
        console.log(GETINTO); // 控制从操作列表进来不跳转
        if (res.data.role !== "pre_sale") {
          this.$router.push({ path: "/projectlist" });
        } else if (
          res.data.role == "pre_sale" &&
          res.data.isProject == true &&
          GETINTO != 1
        ) {
          this.$router.push({ path: "/projectlist" });
        }
      } else {
      }
    },
    funcUrlDel(name) {
      let loca = window.location;
      let baseUrl = loca.origin + loca.pathname + "?";
      let query = loca.search.substr(1);
      if (query.indexOf(name) > -1) {
        let obj = {};
        let arr = query.split("&");
        for (let i = 0; i < arr.length; i++) {
          arr[i] = arr[i].split("=");
          obj[arr[i][0]] = arr[i][1];
        }
        delete obj[name];
        let url =
          baseUrl +
          JSON.stringify(obj)
            .replace(/[\"\{\}]/g, "")
            .replace(/\:/g, "=")
            .replace(/\,/g, "&");
        window.location.href = url;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.explain {
  .bgimage {
    margin-bottom: 0.4rem /* 20/50 */;
  }
  p {
    padding-left: 0.6rem /* 30/50 */;
    padding-right: 0.7rem /* 35/50 */;
    font-size: 0.52rem /* 26/50 */;
  }
  .van-button {
    width: 13.82rem /* 691/50 */;
    position: absolute;
    left: 50%;
    top: 60%;
    transform: translate(-50%, -50%);
  }
}
</style>




