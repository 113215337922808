<template>
  <div class="custom_application">
    <phoneHander></phoneHander>
    <div class="bgimage">
      <img src="../assets/img/banner.png" alt="">
    </div>
    <div class="islaft"></div>
    <ul>
      <li v-for="item in list" :key="item.id" @click="fillin(item.text,item.items)">
        <van-icon name="arrow" size='16px' :class="{active : item.state}"/>
        <p class="p1">{{item.text}}</p>
        <template>
          <!-- {{item.items}} -->
          <p v-if="item.items.length < 2" class="p2" :class="{active : !item.items[0].status}">
            {{item.items[0].status == false ? '未填' : '已填'}}
          </p>
          <p v-if="item.items.length >= 2" class="p2" :class="{active : !item.items[0].status && !item.items[1].status}">
           {{ item.items[0].status || item.items[1].status ? '已填' : '未填'}}
          </p>
        </template>
      </li>
    </ul>
  </div>
  
</template>

<script>
import phoneHander from "../components/phoneHander";

export default {
  components: {
    phoneHander
  },
  data() {
    return {
      list: [],
      drawer: true,
      projectid: ""
      // index:1
    };
  },
  created() {
    // console.log(this.REDIRECT_URI);
    this.projectid = localStorage.getItem("projectid");
    console.log(this.projectid);
    this.getProject();
  },
  mounted() {},
  methods: {
    async getProject() {
      const res = await this.axios.get(
        `/api/frontend/new/template/${this.projectid}/items`
      );
      console.log(res);
      if (res.status_code === 200) {
        let IDLIST = [];
        res.data.forEach(item => {
          if (item.type == "basic") {
            item.text = "项目基本信息";
          } else if (item.type == "software") {
            item.text = "软件配置需求";
          } else if (item.type == "hardware") {
            item.text = "硬件配置需求";
          } else if (item.type == "parts") {
            item.text = "随机配件及资料";
          } else if (item.type == "brand") {
            item.text = "品牌及服务";
          }
          // console.log(item);
          item.items.forEach(val => {
            console.log(val);
            IDLIST.push(val.id);
          });
          // IDLIST.push(item.id);
        });
        // console.log(IDLIST);
        localStorage.setItem("idlist", JSON.stringify(IDLIST));
        // console.log(res.data.items);
        this.list = res.data;
      }
    },

    fillin(text, items) {
      console.log(items);
      if (text === "项目基本信息") {
        this.$router.push({
          path: `/basicinfo?id=${items[0].id}&status=${items[0].status}`
        });
      } else if (text === "软件配置需求") {
        this.$router.push({
          path: `/pbInfoMantion?id=${items[0].id}&status=${items[0].status}`
        });
      } else if (text === "硬件配置需求") {
        this.$router.push({
          path: `/hcrequire?id=${items[0].id}&status=${items[0].status}`
        });
      } else if (text === "随机配件及资料") {
        this.$router.push({
          path: `/randomaccessories?id=${items[0].id}&status=${items[0].status}`
        });
      } else if (text === "品牌及服务") {
        this.$router.push({
          path: `/brandservice?id=${items[0].id}&status=${items[0].status}`
        });
      }
    }
  }
};
</script>

<style lang="less" scoped>
.custom_application {
  background-color: #f4f7fa;
  .bgimage {
    margin-bottom: 0.4rem /* 20/50 */;
  }
  ul {
    li {
      height: 1.66rem /* 83/50 */;
      background-color: #fff;
      border-bottom: 1px solid #e6e5e5;
      padding-left: 0.64rem /* 32/50 */;
      padding-right: 0.6rem /* 30/50 */;
      .van-icon {
        float: right;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
      .p1 {
        float: left;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        font-size: 0.52rem /* 26/50 */;
      }
      .p2 {
        float: right;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        font-size: 0.52rem /* 26/50 */;
      }
      .active {
        color: red;
      }
    }
  }
}
</style>
