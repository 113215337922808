<template>
  <div class="marketing-audit">
    <div class="choice">
      <van-radio-group v-model="radio" @change="choice">
        <van-radio name='1'>
        通过
        <img
          slot="icon"
          slot-scope="props"
          :src="props.checked ? icon.active : icon.normal"
        >
        </van-radio>
        <van-radio name='2'>
          驳回
          <img
            slot="icon"
            slot-scope="props"
            :src="props.checked ? icon.active : icon.normal"
          >
        </van-radio>
      </van-radio-group>
    </div>
    <van-cell-group >
      <van-cell title="优先级(通过审核时需要选择)" v-if="inputShow" />
      <van-cell :title="priority" is-link @click="Callout" v-if="inputShow" />
      <van-field
        v-model="explain"
        rows="1"
        autosize
        :label="text"
        type="textarea"
        placeholder="请输入文本"
        class="textarea"
      />
    </van-cell-group>
    <van-popup v-model="show" position="bottom" >
      <van-picker :columns="columns" show-toolbar @cancel="onCancel" @confirm="onConfirm" />
    </van-popup>
    <div class="btn">
      <button class="draft-submit" @click="examine">提交</button>
      <button class="draft-submit" @click="goNews">返回列表</button>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      icon: {
        normal: require("../../assets/img/noative.png"),
        active: require("../../assets/img/ative.png")
      },
      radio: "1",
      show: false,
      columns: ["普通", "星级"],
      priority: "默认", // 优先级
      explain: "",
      projectid: "", // 项目id
      isPass: "",
      inputShow:true,
      text:'说明(非必填)',
    };
  },
  created() {
    this.projectid = localStorage.getItem("projectid");
  },
  methods: {
    choice(value){
      console.log(value);
      if(value == 1){
        this.inputShow = true
        this.priority = '默认'
        this.text = '说明(非必填)'
        this.istrue = true
        // 通过
      }else{
        // 未通过
        this.inputShow = false
        this.priority = ''
        this.text = '说明(必填)'
      }
    },
    onConfirm(value, index) {
      console.log(value);
      this.priority = value;
      this.show = false;
    },
    onCancel() {
      this.show = false;
    },
    Callout() {
      this.show = true;
    },
    async examine() {
      if(this.text == '说明(必填)' && this.explain == ''){
        this.$toast('请输入驳回原因')
        return false
      }
      if (this.radio === "1") {
        this.isPass = "pssAdopt";
      } else {
        this.isPass = "pssReject";
      }
      const res = await this.axios.post(
        `/api/frontend/subject/${this.projectid}/operation/${this.isPass}`,
        {
          level: this.priority,
          explain: this.explain
        }
      );
      if (res.status_code === 200) {
        this.$toast("提交成功");
        this.$router.push({ path: "/news" });
      }else{
        this.$toast(res.error);
      }
    },
    goNews() {
      this.$router.push({ path: "/news" });
    }
  }
};
</script>
<style lang="less" scoped>
@jianbian: linear-gradient(90deg, #09aaff, #2685ff);
.marketing-audit {
  .btn {
    width: 100%;
    text-align: center;
    margin-top: 0.8rem /* 40/50 */;
    // background-color: #eff3f6;
    .draft-submit {
      width: 12.38rem /* 619/50 */;
      height: 1.7rem /* 85/50 */;
      background: @jianbian;
      border-radius: 43px;
      color: #fff;
      margin-bottom: 1rem;
      text-align: center;
    }
  }
  padding-bottom: 1rem /* 50/50 */;
  .van-radio-group {
    display: flex;
    padding-left: 0.6rem /* 30/50 */;
  }
  .van-radio {
    width: 6.6rem /* 130/50 */;
    position: relative;
    margin-right: 0.3rem /* 25/50 */;
    margin-bottom: 0.5rem; /* 25/50 */
    /deep/.van-radio__icon {
      height: 1.58rem /* 79/50 */;
      width: 6.6rem /* 130/50 */;
      img {
        height: 1.58rem /* 79/50 */;
        width: 6.6rem /* 130/50 */;
      }
    }
    /deep/.van-radio__label {
      position: absolute;
      margin-left: 0;
      left: 50%;
      transform: translate(-50%);
      width: 100%;
      text-align: center;
    }
  }
  .van-cell {
    color: #8b919a;
    /deep/.van-field__control {
      border: 1px solid #8b919a;
      height: 3rem /* 50/100 */ !important;
      padding-left: 0.5rem;
    }
  }
}
</style>


