<template>
  <div class="register">
    <phoneHander :updata='updata'></phoneHander>
    <div class="registerlist">
      <van-cell-group>
        <van-field v-model="username" label="姓名" placeholder="请输入姓名" :error-message='nameError' @input='changeInput'/>
        <van-field v-model="corporate" label="公司名称" placeholder="请输入公司名称" :error-message='porateError'  @input='changeInput' />
        <van-field 
          v-model="department" 
          label="部门" 
          placeholder="请选择部门" 
          readonly="readonly" 
          :right-icon="isEditUserIcon" 
          @focus='changeDep' 
          :error-message='deparError'  
          @blur='changeInput' 
          :class="{colash:isEditColor}"/>
        <van-field 
          v-model="jobquestions" 
          label="职务" 
          placeholder="请选择职务" 
          readonly="readonly" 
          :right-icon="isEditUserIcon" 
          @focus='changeDep' 
          :error-message='jobqueError'  
          @blur='changeInput' 
          :class="{colash:isEditColor}"/>
        <van-field v-model="mailbox" label="邮箱" placeholder="请输入邮箱" :error-message='mailboxError'  @input='changeInput'/>
        <van-field 
          v-if="isshowRegion" 
          v-model="region" 
          label="所在大区" 
          placeholder="请选择大区" 
          readonly="readonly" 
          :right-icon="isEditUserIcon"
          @focus='changeDep' 
          :error-message='regionError'  
          @blur='changeInput' 
          :class="{colash:isEditColor}"/>
      </van-cell-group>
    </div>
    <button class="submit" @click="clickRegister">{{btnName}}</button>
    <p>提示：{{textName}}后请等待管理员审核</p>
    <van-popup v-model="show" position="bottom">
      <van-picker
        show-toolbar
        :title="title"
        :columns="columns"
        @cancel="onCancel"
        @confirm="onConfirm"
        :loading='isloading'
      />
    </van-popup>
  </div>
</template>

<script>
import phoneHander from "../components/phoneHander";
import { text } from "body-parser";

export default {
  components: {
    phoneHander
  },
  data() {
    return {
      username: "", // 用户名
      corporate: "联想", // 公司名称
      department: "", // 部门
      jobquestions: "", //职问
      mailbox: "", //邮箱
      region: "", //大区
      show: false,
      columns: [], // 选择器数字
      depArr: [], // 部门数据
      postArr: [], // 职务数据
      regionArr: [], // 大区数据
      index: 0,
      title: "",
      isloading: true,
      nameError: "",
      porateError: "", // 公司名称校验
      deparError: "", // 部门校验
      jobqueError: "", // 职务校验
      mailboxError: "", // 邮箱校验
      regionError: "", // 大区校验
      branch_id: "", // 部门id
      position_id: "", // 职务id
      region_id: "", // 所在大区id
      openid: "",
      updata: "",
      btnName: "注册账号",
      textName: "注册",
      updatalist: null,
      isshowRegion: true,
      isEdit: 0
    };
  },
  created() {
    this.openid = localStorage.getItem("openid");
    this.updata = this.getQueryValue("upadta");
    if (this.updata == 1) {
      this.getIsEdit();
      this.btnName = "确认修改";
      this.updatalist = JSON.parse(localStorage.getItem("updatalist"));
      console.log(this.updatalist);
      this.username = this.updatalist.name;
      this.mailbox = this.updatalist.email;
      this.corporate = this.updatalist.corporate_name;
      this.position_id = this.updatalist.position_id;
      this.branch_id = this.updatalist.branch_id;
      this.region_id = this.updatalist.region_id;
      this.textName = "修改";
      if (!this.region_id) {
        this.isshowRegion = false;
      }
      this.getBuMenEdit();
      this.getPostEit(this.branch_id);
      this.getRegionEdit();
    } else {
    }
  },
  computed: {
    isEditUserIcon() {
      if (this.isEdit == 0) {
        return "arrow";
      }
    },
    isEditColor() {
      if (this.isEdit == 0) {
        return false;
      } else {
        return true;
      }
    }
  },
  methods: {
    async getIsEdit() {
      const res = await this.axios.get("api/frontend/member/is_edit");
      if (res.status_code == 200) {
        this.isEdit = res.data.is_edit;
        console.log(this.isEdit);
      }
    },
    clickRegister() {
      if (!this.username) {
        this.nameError = "请输入用户名称";
        return;
      }
      if (!this.corporate) {
        this.porateError = "请输入公司名称";
        return;
      }
      if (!this.department) {
        this.deparError = "请选择部门";
        return;
      }
      if (!this.jobquestions) {
        this.jobqueError = "请选择职务";
        return;
      }
      if (!this.mailbox) {
        this.mailboxError = "请输入邮箱";
        return;
      }
      if (!this.region && this.isshowRegion) {
        this.regionError = "请选择大区";
        return;
      }
      if (this.updata == 1) {
        console.log("修改资源");
        this.EditPersonal();
      } else {
        this.Submit();
      }
    },
    // 修改资料
    async EditPersonal() {
      const res = await this.axios.put("api/frontend/member/update", {
        name: this.username,
        corporate_name: this.corporate,
        branch_id: this.branch_id,
        position_id: this.position_id,
        email: this.mailbox,
        region_id: this.region_id,
        openid: this.openid
      });
      if (res.status_code === 200) {
        this.$router.push({ path: "/userinformation" });
      } else {
        this.$toast(res.error);
      }
    },
    // 注册账号
    async Submit() {
      const res = await this.axios.post("api/frontend/member", {
        name: this.username,
        corporate_name: this.corporate,
        branch_id: this.branch_id,
        position_id: this.position_id,
        email: this.mailbox,
        region_id: this.region_id,
        openid: this.openid
      });
      if (res.status_code === 200) {
        localStorage.setItem("token", res.token);
        this.$router.push({ path: "/explain" });
      } else {
        this.$toast(res.error);
      }
    },
    changeInput() {
      if (this.username) {
        this.nameError = "";
      }
      if (this.corporate) {
        this.porateError = "";
      }
      if (this.department) {
        this.deparError = "";
      }
      if (this.jobquestions) {
        this.jobqueError = "";
      }
      if (this.mailbox) {
        this.mailboxError = "";
      }
      if (this.region) {
        this.regionError = "";
      }
    },
    // 获取部门
    async getBuMen() {
      this.columns = [];
      this.isloading = true;
      const res = await this.axios.get("api/frontend/branch");
      if (res.status_code === 200) {
        this.depArr = res.data;
        res.data.forEach(item => {
          this.columns.push(item.name);
        });
        this.isloading = false;
      } else {
        this.$toast(res.error);
      }
    },
    // 获取部门数据回显
    async getBuMenEdit() {
      this.columns = [];
      const res = await this.axios.get("api/frontend/branch");
      if (res.status_code === 200) {
        console.log(res);
        if (this.updata == 1) {
          let Arr = res.data;
          Arr = Arr.filter(val => val.id == this.branch_id);
          this.department = Arr[0].name;
        }
      } else {
        this.$toast(res.error);
      }
    },
    // 获取部门下职务
    async getPost(depId) {
      this.columns = [];
      this.isloading = true;
      const res = await this.axios.get(`api/frontend/branch?pid=${depId}`);
      if (res.status_code === 200) {
        this.postArr = res.data;
        res.data.forEach(item => {
          this.columns.push(item.name);
        });
        this.isloading = false;
      } else {
        this.$toast(res.error);
      }
    },
    // 获取部门下职务数据回显
    async getPostEit(depId) {
      this.columns = [];
      const res = await this.axios.get(`api/frontend/branch?pid=${depId}`);
      if (res.status_code === 200) {
        if (this.updata == 1) {
          let Arr = res.data;
          Arr = Arr.filter(val => val.id == this.position_id);
          this.jobquestions = Arr[0].name;
        }
      } else {
        this.$toast(res.error);
      }
    },
    // 获取所在大区
    async getRegion() {
      this.columns = [];
      this.isloading = true;
      const res = await this.axios.get("api/frontend/region");
      if (res.status_code === 200) {
        this.regionArr = res.data;
        res.data.forEach(item => {
          this.columns.push(item.name);
        });
        this.isloading = false;
      } else {
        this.$toast(res.error);
      }
    },
    // 获取所在大区数据回显
    async getRegionEdit() {
      this.columns = [];
      const res = await this.axios.get("api/frontend/region");
      if (res.status_code === 200) {
        console.log(res);
        if (this.updata == 1) {
          let Arr = res.data;
          Arr = Arr.filter(val => val.id == this.region_id);
          this.region = Arr[0].name;
        }
      } else {
        this.$toast(res.error);
      }
    },
    changeDep(e) {
      console.log(e.srcElement.placeholder);
      if (e.srcElement.placeholder == "请选择部门") {
        if (this.updata == 1 && this.isEdit > 0) {
          this.$toast("不可修改");
        } else {
          this.show = true;
          this.index = 1; // 选择部门
          this.title = "部门";
          this.getBuMen();
        }
      } else if (e.srcElement.placeholder == "请选择职务") {
        if (this.updata == 1 && this.isEdit > 0) {
          this.$toast("不可修改");
        } else {
          this.index = 2;
          this.title = "职务";
          if (!this.department) {
            this.$toast("请先选择部门");
          } else {
            this.getPost(this.branch_id);
            this.show = true;
          }
        }
      } else if (e.srcElement.placeholder == "请选择大区") {
        if (this.updata == 1 && this.isEdit > 0) {
          this.$toast("不可修改");
        } else {
          this.getRegion();
          this.title = "大区";
          this.index = 3;
          this.show = true;
        }
      }
    },
    // 选中对应数据和id
    onConfirm(value, index) {
      if (this.index === 1) {
        this.department = value;
        let newData;
        newData = this.depArr.filter(item => item.name == this.department);
        this.branch_id = newData[0].id;
        console.log(this.branch_id);
        if (this.branch_id == 2) {
          this.isshowRegion = false;
        } else {
          this.isshowRegion = true;
        }
        this.getPost(newData[0].id);
        this.jobquestions = "";
      } else if (this.index === 2) {
        this.jobquestions = value;
        let newData = this.postArr.filter(
          item => item.name == this.jobquestions
        );
        this.position_id = newData[0].id;
      } else if (this.index === 3) {
        this.region = value;
        let newData = this.regionArr.filter(item => item.name == this.region);
        this.region_id = newData[0].id;
      }
      this.show = false;
    },
    onCancel() {
      this.$toast("取消");
      this.show = false;
    },
    getQueryValue(name) {
      var url = window.location.href;
      var result = url.match(new RegExp("[?&]" + name + "=([^&]+)", "i"));

      if (result == null || result.length < 1) {
        return "";
      }
      return decodeURI(result[1]);
    },
    funcUrlDel(name) {
      let loca = window.location;
      let baseUrl = loca.origin + loca.pathname + "?";
      let query = loca.search.substr(1);
      if (query.indexOf(name) > -1) {
        let obj = {};
        let arr = query.split("&");
        for (let i = 0; i < arr.length; i++) {
          arr[i] = arr[i].split("=");
          obj[arr[i][0]] = arr[i][1];
        }
        delete obj[name];
        let url =
          baseUrl +
          JSON.stringify(obj)
            .replace(/[\"\{\}]/g, "")
            .replace(/\:/g, "=")
            .replace(/\,/g, "&");
        window.location.href = url;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.register {
  .submit {
    width: 13.82rem /* 691/50 */;
    height: 1.7rem /* 85/50 */;
    background: linear-gradient(90deg, #09aaff, #2685ff);
    color: #fff;
    border-radius: 0.86rem /* 43/50 */;
    margin-left: 0.6rem /* 30/50 */;
    margin-top: 0.8rem /* 40/50 */;
  }
  p {
    text-align: center;
    color: #bec5cc;
    margin-top: 0.6rem /* 30/50 */;
  }
  .colash {
    /deep/.van-field__control {
      color: #bec5cc;
    }
  }
}
</style>


