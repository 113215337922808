<template>
  <div class="viewfeedback">
    <p>请填写解决方案</p>
    <div class="shang clearfix">
      <p class="shangchuang">上传图片（非必填）</p>
    </div>
    <!-- <van-button type="primary" size="small" round @click="upwximg">点击上传图片</van-button> -->
    <div class="clearfix">
      <van-uploader v-model="fileList" accept="image/*" :after-read="afterRead" :preview-full-image='false' @delete='deleImg' multiple  ></van-uploader>
      <!-- <img v-for="item in fileList" :src="item" @click="lockImg(item)" style="width:80px;height:80px;float:left;margin-left:15px;margin-top:15px;"> -->
    </div>
    <span>解决方案描述（必填）</span>
    <van-field
      v-model="message"
      type="textarea"
      placeholder="解决方案描述（必填）"
      rows="3"
      autosize
      class="textareas"
    />
    <button class="supplement" @click="changeSupp" >提交</button>
    <!-- <div class="btnlist">
      <button >搁置项目</button>
      <button >复制</button>
      <button >移交他人</button>
    </div> -->
    <van-overlay :show="show" lock-scroll>
      <van-loading type="spinner" color="#1989fa" />
    </van-overlay>
    <!-- <van-popup v-model="showimg">
      <img :src="imgURL" alt="">
    </van-popup> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      message: "",
      number: "",
      passworld: "",
      projectid: "",
      fileList: [],
      hander: "http://scheme.dnopen.com/",
      imgList: [],
      show: false,
      showimg: false,
      imgURL: ""
    };
  },
  created() {
    this.projectid = localStorage.getItem("projectid");
  },
  methods: {
    afterRead(file) {
      this.uploadimg(file);
    },
    deleImg(file, fileList) {
      this.imgList.splice(fileList.index, 1);
      console.log(this.imgList);
    },
    // 上传图片
    async uploadimg(file) {
      if (Array.isArray(file)) {
        // 多图依次上传
        file.forEach(item => {
          this.updataApi(item);
        });
      } else {
        this.updataApi(file);
      }
    },
    async updataApi(file) {
      this.show = true;
      let params = new FormData();
      params.append("file", file);
      const res = await this.axios.post("/api/frontend/upload", {
        file: file.content
      });
      if (res.status_code === 200) {
        this.imgList.push(res.data);
        this.show = false;
      } else if (res.error == "The file failed to upload") {
        this.$toast("上传失败请从新上传");
        this.show = false;
      } else {
        this.$toast(res.error);
        this.show = false;
      }
    },

    async changeSupp() {
      if (this.message == "") {
        this.$toast("请填写解决方案描述");
        return false;
      }
      const res = await this.axios.post(
        `/api/frontend/subject/${this.projectid}/operation/confirm`,
        {
          explain: this.message,
          imgurl: this.imgList
        }
      );
      if (res.status_code === 200) {
        this.$toast("提交成功");
        this.$router.push({ path: "/news" });
      } else {
        this.$toast(res.error);
      }
    }
  }
};
</script>

<style lang="less" scoped>
@jianbian: linear-gradient(90deg, #09aaff, #2685ff);
.viewfeedback {
  text-align: center;
  padding-bottom: 2rem /* 100/50 */;
  position: relative;
  .shang {
    height: 1rem;
  }
  .shangchuang {
    position: absolute;
    margin-left: 0.9rem;
    left: 0;
  }
  .van-uploader {
    margin-left: 0.9rem;
    float: left;
  }
  .btnlist {
    width: 100%;
    padding-left: 0.56rem /* 28/50 */;
    padding-right: 0.56rem /* 28/50 */;
    margin-bottom: 1rem /* 50/50 */;
    display: flex;
    justify-content: space-between;
    button {
      width: 31%;
      height: 1.7rem /* 85/50 */;
      border-radius: 0.86rem /* 43/50 */;
      background: @jianbian;
      color: #fff;
    }
  }
  .supplement {
    width: 13.82rem /* 691/50 */;
    height: 1.7rem /* 85/50 */;
    background: @jianbian;
    border-radius: 0.86rem /* 43/50 */;
    color: #fff;
    margin-top: 0.8rem /* 40/50 */;
    margin-bottom: 0.8rem /* 40/50 */;
  }
  // /deep/.van-field__control {
  //   border: 1px solid #8b919a;
  //   height: 3rem /* 50/100 */ !important;
  //   padding-left: 0.5rem;
  // }
  .textareas {
    border: 1px solid #8b919a;
    height: 3rem /* 50/100 */ !important;
    padding-left: 0.5rem;
    margin-left: 0.7rem;
    margin-right: 0.7rem;
    max-width: 90%;
  }
  .van-loading {
    position: absolute;
    bottom: 13rem;
    left: 50%;
    transform: translate(-50%);
  }
}
</style>
