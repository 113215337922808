<template>
  <div class="user">
    <phoneHander></phoneHander>
    <div class="newlist">
      <van-cell-group>
        <van-cell title="姓名" :value="name" />
        <van-cell title="公司名称" :value="corporate_name" />
        <van-cell title="部门" is-link :value="branch" />
        <van-cell title="职务" is-link :value="position" />
        <van-cell title="邮箱" :value="email" />
        <van-cell v-if="region" title="所在大区" is-link :value="region" />
      </van-cell-group>
    </div>
    <div class="cao-zuo">
      <h3>用户状态：
        <span v-if="is_reviewed == 1">已审核</span>
        <span v-if="is_reviewed == 0">未审核</span>
        <span v-if="is_reviewed == 2">已驳回</span>
      </h3>
      <button @click="$router.push({path:'/register?upadta=1'})">修改信息</button>
      <p>提示：修改资料管理员需要再次审核</p>
    </div>
      <div class="module-status" id="module2">
      <div class="status-title">账号操作记录</div>
      <el-timeline>
        <el-timeline-item
          placement='top'
          v-for="(activity, index) in activities"
          :key="index"
          :icon="activity.icon"
          :type="activity.type"
          :color="activity.color"
          :size="activity.size"
          :timestamp="activity.timestamp">
          <div class="status-agent">{{activity.content}}</div>
        </el-timeline-item>
      </el-timeline>
    </div>
  </div>
</template>

<script>
import phoneHander from "../components/phoneHander";

export default {
  components: {
    phoneHander
  },
  data() {
    return {
      activities: [
        // {
        //   content: "售前 李四 填写资料 提交审核",
        //   timestamp: "2018-04-12 20:46",
        //   size: "large",
        //   type: "primary",
        //   icon: "el-icon-more"
        // },
        // {
        //   content: "营销主管 张三 审核通过",
        //   timestamp: "2018-04-03 20:46",
        //   size: "",
        //   type: "",
        //   color: "#0bbd87",
        //   icon: "el-icon-check"
        // },
        // {
        //   content: "售前 李四 填写资料 提交审核",
        //   timestamp: "2018-04-03 20:46",
        //   size: "large",
        //   type: "primary",
        //   icon: "el-icon-more"
        // },
        // {
        //   content: "营销主管 张三 审核未通过",
        //   timestamp: "2018-04-03 20:46",
        //   size: "large",
        //   type: "danger",
        //   icon: "el-icon-warning-outline"
        // }
      ],
      name: "",
      email: "",
      corporate_name: "",
      branch_id: "",
      branch: "",
      position_id: "",
      position: "",
      region_id: "",
      region: "",
      is_reviewed: "",
      depArr: [],
      postArr: [],
      regionArr: [],
      recordList: []
    };
  },
  created() {
    this.getuser();
  },
  methods: {
    async getuser() {
      const res = await this.axios.get("api/frontend/member/me");
      if (res.status_code === 200) {
        console.log(res);
        localStorage.setItem("updatalist", JSON.stringify(res.data));
        this.recordList = res.data.actions;
        console.log(this.recordList);
        this.recordList.forEach(item => {
          console.log(item);
          this.activities.push({
            timestamp: item.updated_at,
            size: "large",
            type: item.type,
            icon: item.icon,
            color: item.color,
            content: item.content
          });
        });
        this.name = res.data.name;
        this.email = res.data.email;
        this.corporate_name = res.data.corporate_name;
        this.branch_id = res.data.branch_id; // 部门id
        this.position_id = res.data.position_id; // 职务id
        this.region_id = res.data.region_id; // 大区id
        this.is_reviewed = res.data.is_reviewed; // 账号审核状态
        this.getBuMen();
        this.getPost();
        this.getRegion();
      } else {
        this.$toast(res.error);
      }
    },
    async getBuMen() {
      const res = await this.axios.get("api/frontend/branch");
      if (res.status_code === 200) {
        this.depArr = res.data;
        // console.log(this.depArr[0].id == this.branch_id);
        // console.log(this.depArr[0].id);
        // console.log(this.branch_id);
        let newArr = this.depArr.filter(ITEM => ITEM.id == this.branch_id);
        this.branch = newArr[0].name;
      } else {
        this.$toast(res.error);
      }
    },
    async getPost() {
      const res = await this.axios.get(
        `api/frontend/branch?pid=${this.branch_id}`
      );
      if (res.status_code === 200) {
        this.postArr = res.data;
        let newArr = this.postArr.filter(ITEM => ITEM.id == this.position_id);
        this.position = newArr[0].name;
      } else {
        this.$toast(res.error);
      }
    },
    async getRegion() {
      const res = await this.axios.get("api/frontend/region");
      if (res.status_code === 200) {
        this.regionArr = res.data;
        let newArr = this.regionArr.filter(ITEM => ITEM.id == this.region_id);
        console.log(newArr);
        if (newArr.length > 0) {
          this.region = newArr[0].name;
        }
      } else {
        this.$toast(res.error);
      }
    }
  }
};
</script>

<style lang="less" scoped>
@sizsColor: #b4bac3;
.user {
  .cao-zuo {
    text-align: center;
    padding-top: 0.8rem /* 40/50 */;
    height: 5.84rem /* 292/50 */;
    font-size: 0.52rem /* 26/50 */;
    h3 {
      color: #7a8289;
      span {
        color: #19c642;
      }
    }
    button {
      width: 13.82rem /* 691/50 */;
      height: 1.7rem /* 85/50 */;
      color: #fff;
      background: linear-gradient(90deg, #09aaff, #2685ff);
      border-radius: 0.86rem /* 43/50 */;
      margin-top: 0.82rem /* 41/50 */;
    }
    p {
      color: #bec5cc;
      margin-top: 0.6rem /* 30/50 */;
    }
  }
  .module-status {
    background-color: #fff;
    margin-top: 0.4rem /* 20/50 */;
    .status-title {
      height: 1.36rem /* 68/50 */;
      background-color: #eff3f6;
      line-height: 1.36rem /* 68/50 */;
      text-align: center;
      color: #8b919a;
    }
    .el-timeline {
      margin-left: 0.6rem /* 30/50 */;
      margin-top: 0.98rem /* 49/50 */;
    }
    .status-information {
      color: @sizsColor;
    }
    .status-agent {
      position: absolute;
      top: 0;
      right: 0.4rem /* 20/50 */;
      color: @sizsColor;
    }
  }
}
</style>


