<template>
  <div>
    <phoneHander></phoneHander>
    <div class="bgimage">
      <img src="../assets/img/dzbanner.png" alt="">
    </div>
    <div>
      <h4>资料</h4>
      <van-cell-group>
        <div class="checkedbox">
          <div class="bgbox">
            <span class="span1">已选:</span>
            <span class="span2" v-for="isSelect in result" :key="isSelect">{{isSelect}}</span>
          </div>
          <van-checkbox-group v-model="result">
            <van-checkbox
              v-for="checkbox in list"
              :key="checkbox"
              :name="checkbox"
              @click="changeCheck(checkbox)"
            >
              {{checkbox}}
              <img
                slot="icon"
                slot-scope="props"
                :src="props.checked ? icon.active : icon.normal"
              >
            </van-checkbox>
          </van-checkbox-group>
        </div>
      </van-cell-group>
    </div>
    <div>
      <h4>配件</h4>
      <van-cell-group>
        <van-field v-model="value" label="线缆" :error-message="error"/>
        <van-field v-model="value1" label="挂架" :error-message="error2"/>
        <van-field v-model="value2" label="键鼠" :error-message="error3"/>
      </van-cell-group>
    </div>
    <div>
      <h4>其他需求</h4>
      <van-field v-model="value3"  rows='3' type="textarea" :error-message="error4"/>
    </div>
    <div class="flooter">
      <van-button size="large" round type="info"  @click="Submit">保存并下一步</van-button>
    </div>
  </div>
</template>
<script>
import phoneHander from "../components/phoneHander";
import fromCreates from "../components/create-form/createform";
export default {
  components: {
    phoneHander,
    fromCreates
  },
  data() {
    return {
      list: [],
      projectid: "",
      is_status: "",
      icon: {
        normal: require("../assets/img/noative.png"),
        active: require("../assets/img/ative.png")
      },
      result: [], // 资料
      list: ["说明书", "保修卡", "都不需要"], // 资料
      show: false, // 软件认证需求
      value: "不需要", // 线缆
      value1: "不需要",
      value2: "不需要",
      value3: "不需要",
      error: "",
      error2: "",
      error3: "",
      error4: ""
    };
  },
  created() {
    // this.projectid = localStorage.getItem("projectid");
    this.projectid = localStorage.getItem("projectid");
    if (this.projectid) {
      this.getEditList();
    }
  },
  computed: {},
  methods: {
    Submit() {
      if (this.result.length < 1) {
        this.$toast("请选择资料");
        return false;
      }
      if (!this.value) {
        this.$toast("请输入线缆");
        this.error = "请输入线缆";
        return false;
      }
      if (!this.value1) {
        this.$toast("请输入挂架");
        this.error2 = "请输入挂架";
        return false;
      }
      if (!this.value2) {
        this.$toast("请输入键鼠");
        this.error3 = "请输入键鼠";
        return false;
      }
      if (!this.value3) {
        this.$toast("请输入其它需求");
        this.error4 = "请输入其它需求";
        return false;
      }
      this.subject();
    },
    async subject() {
      const res = await this.axios.post(
        `api/frontend/subject/${this.projectid}/item/parts`,
        {
          material: this.result,
          cable: this.value,
          pylons: this.value1,
          mouse: this.value2,
          other_needs: this.value3
        }
      );
      console.log(res);
      if (res.status_code === 200) {
        this.$router.push({ path: "/brandservice" });
      }
    },
    async getEditList() {
      const res = await this.axios.get(
        `api/frontend/subject/${this.projectid}/item/parts`
      );
      console.log(res);
      if (res.status_code === 200) {
        if (res.data.itemable) {
          let list = res.data.itemable;
          this.result = list.material;
          this.value = list.cable;
          this.value1 = list.pylons;
          this.value2 = list.mouse;
          this.value3 = list.other_needs;
        }
      }
    },
    changeCheck(checkbox) {
      if (checkbox === "都不需要") {
        this.result = [];
        this.result.push("都不需要");
      } else {
        this.remove("都不需要");
      }
    },
    remove(val) {
      let index = this.result.indexOf(val);
      if (index > -1) {
        this.result.splice(index, 1);
      }
    },
    getQueryValue(name) {
      var url = window.location.href;
      var result = url.match(new RegExp("[?&]" + name + "=([^&]+)", "i"));

      if (result == null || result.length < 1) {
        return "";
      }
      return decodeURI(result[1]);
    }
  }
};
</script>

<style lang="less" scoped>
h4 {
  font-size: 0.62rem /* 26/50 */;
  margin-top: 1.18rem /* 59/50 */;
  margin-bottom: 0.46rem /* 23/50 */;
  margin-left: 0.6rem /* 30/50 */;
  color: #000;
  font-weight: 600;
}
.flooter {
  width: 80%;
  margin: 0 auto;
  margin-top: 1rem; /* 50/50 */
  margin-bottom: 1rem; /* 50/50 */
}
.checkedbox {
  padding-top: 0.4rem /* 20/50 */;
  background-color: #fff;
  .bgbox {
    margin-bottom: 0.4rem /* 20/50 */;
  }
  .span1 {
    margin-left: 0.2rem /* 10/50 */;
    font-size: 0.52rem; /* 26/50 */
    margin-bottom: 0.4rem /* 20/50 */;
  }
  .span2 {
    font-size: 0.52rem /* 26/50 */;
    color: #408bea;
    background-color: #dfefff;
    margin-left: 0.2rem /* 10/50 */;
    margin-bottom: 0.4rem /* 20/50 */;
  }
}
.van-checkbox-group {
  margin-left: 0.2rem;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  .van-checkbox {
    width: 4.2rem /* 130/50 */;
    position: relative;
    margin-right: 0.3rem /* 25/50 */;
    margin-bottom: 0.5rem;
    /deep/.van-checkbox__icon {
      height: 1.58rem /* 79/50 */;
      width: 4.2rem /* 130/50 */;
      img {
        height: 1.58rem /* 79/50 */;
        width: 4.2rem /* 130/50 */;
      }
    }
    /deep/.van-checkbox__label {
      position: absolute;
      margin-left: 0;
      left: 50%;
      transform: translate(-50%);
      width: 100%;
      text-align: center;
    }
  }
}
/deep/.van-field__value {
  border: 1px solid #b2bdcf;
}
</style>


