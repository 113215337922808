<template>
  <div>
    <phoneHander></phoneHander>
    <div class="bgimage">
      <img src="../assets/img/dzbanner.png" alt="">
    </div>
    <div v-if="show">
      <h4>品牌需求</h4>
      <div class="radios clearfix">
        <van-radio-group v-model="radioChecked" class="clearfix">
          <van-radio v-for="radios in radioList" :key="radios" :name='radios'>
          {{radios}}
          <img
            slot="icon"
            slot-scope="props"
            :src="props.checked ? icon.active : icon.normal"
          >
          </van-radio>
        </van-radio-group>
      </div>
    </div>
    <div v-if="show2">
      <h4>服务年限</h4>
      <van-tabs v-model="active" @click="onClick">
        <van-tab title="不需要" ></van-tab>
        <van-tab title="2年" >
          <div class="ele-radio">
            <el-radio-group v-model="checked">
              <h2>服务模式</h2>
              <el-radio :label="eleradio" v-for="eleradio in eleRadioList" :key="eleradio">{{eleradio}}</el-radio>
            </el-radio-group>
          </div>
        </van-tab>
        <van-tab title="3年">
          <div class="ele-radio">
            <el-radio-group v-model="checked2">
              <h2>服务模式</h2>
              <el-radio :label="eleradio" v-for="eleradio in eleRadioList" :key="eleradio">{{eleradio}}</el-radio>
            </el-radio-group>
          </div>
        </van-tab>
      </van-tabs>
    </div>
    <div class="flooter">
      <van-button size="large" round type="info" @click="Submit">保存并下一步</van-button>
    </div>
  </div>
</template>
<script>
import phoneHander from "../components/phoneHander";
import fromCreates from "../components/create-form/createform";
export default {
  components: {
    phoneHander,
    fromCreates
  },
  data() {
    return {
      list: [],
      active: 0,
      icon: {
        normal: require("../assets/img/noative.png"),
        active: require("../assets/img/ative.png")
      },
      projectid: "",
      is_status: "",
      radioChecked: "",
      radiovalue: "",
      radioList: ["联想", "白牌", "OEM"],
      checked: "", // 两年
      eleRadioList: [
        "5*9 联想400提供上门维修服务",
        "7*24 联想400提供上门维修服务",
        "集成商提供服务",
        "渠道提供服务"
      ], // 两年
      checked2: "", // 三年
      eleRadioList2: [
        "5*9 联想400提供上门维修服务",
        "7*24 联想400提供上门维修服务",
        "集成商提供服务",
        "渠道提供服务"
      ], // 三年
      show: true,
      show2: true,
      year: ""
    };
  },
  created() {
    this.getShowAndHiden();
    this.projectid = localStorage.getItem("projectid");
    if (this.projectid) {
      this.getEditList();
    }
  },
  computed: {},

  methods: {
    Submit() {
      if (!this.radioChecked) {
        this.$toast("请选择品牌需求");
        return false;
      }
      if (this.active == 1) {
        if (!this.checked) {
          this.$toast("请选择2年对应服务");
          return false;
        }
      }
      if (this.active == 2) {
        if (!this.checked2) {
          this.$toast("请选择3年对应服务");
          return false;
        }
      }
      this.subject();
    },
    async subject() {
      const res = await this.axios.post(
        `api/frontend/subject/${this.projectid}/item/brand`,
        {
          brand_demand: this.radioChecked,
          year: this.year,
          pattern: this.active == 1 ? this.checked : this.checked2
        }
      );
      console.log(res);
      if (res.status_code === 200) {
        if (res.data.isComplete === false) {
          this.$toast("项目资料填写不完整，请完善资料");
        } else {
          this.$router.push({ path: "/preview" });
        }
      }
    },
    async getEditList() {
      const res = await this.axios.get(
        `api/frontend/subject/${this.projectid}/item/brand`
      );
      console.log(res);
      if (res.status_code === 200) {
        if (res.data.itemable) {
          let list = res.data.itemable;
          if (list.year === "2年") {
            this.active = 1;
          } else if (list.year === "3年") {
            this.active = 2;
          }
          this.radioChecked = list.brand_demand;
          this.checked = list.pattern;
          this.year = list.year;
        }
      }
    },
    onClick(index, title) {
      if (title === "不需要") {
        this.checked = "";
        this.checked2 = "";
      } else if (title === "2年") {
        this.checked = "";
        this.year = "2年";
      } else if (title === "3年") {
        this.year = "3年";
        this.checked = "";
      }
    },
    async getShowAndHiden() {
      const res = await this.axios.get(`/api/frontend/new/template/brand`);
      console.log(res);
      if (res.status_code === 200) {
        this.showList = res.data;
        if (this.showList[0].is_show == 1) {
          this.show = true;
        } else {
          this.show = false;
        }
        if (this.showList[1].is_show == 1) {
          this.show2 = true;
        } else {
          this.show2 = false;
        }
      }
    },
    getQueryValue(name) {
      var url = window.location.href;
      var result = url.match(new RegExp("[?&]" + name + "=([^&]+)", "i"));
      if (result == null || result.length < 1) {
        return "";
      }
      return decodeURI(result[1]);
    }
  }
};
</script>

<style lang="less" scoped>
/deep/.van-tabs__line {
  background-color: #408bea;
  width: 34.5%;
  top: 0;
}
.ele-radio {
  .el-radio-group {
    width: 100%;
    background-color: #fff;
    .el-radio {
      width: 100%;
      display: block;
      height: 1.64rem /* 82/50 */;
      border-bottom: 1px solid #e0e4ed;
      padding-top: 0.4rem /* 20/50 */;
      padding-left: 0.6rem /* 30/50 */;
      box-sizing: border-box;
    }
  }
}
h4 {
  font-size: 0.62rem /* 26/50 */;
  margin-top: 1.18rem /* 59/50 */;
  margin-bottom: 0.46rem /* 23/50 */;
  margin-left: 0.6rem /* 30/50 */;
  color: #000;
  font-weight: 600;
}
/deep/.van-radio__icon {
  height: 1.58rem /* 79/50 */;
  width: 4.5rem /* 130/50 */;
  img {
    height: 1.58rem /* 79/50 */;
    width: 4.5rem /* 130/50 */;
  }
}
.van-radio {
  width: 4.5rem /* 130/50 */;
  position: relative;
  // float: left;
  margin-right: 0.3rem /* 25/50 */;
  margin-bottom: 0.5rem; /* 25/50 */
}
/deep/.van-radio__label {
  position: absolute;
  margin-left: 0;
  left: 50%;
  transform: translate(-50%);
  width: 100%;
  text-align: center;
}
.radios {
  // padding-left: 0.4rem /* 20/50 */;
  padding-top: 0.4rem /* 20/50 */;
  // padding-bottom: 0.4rem /* 20/50 */;
  background-color: #fff;
}
.van-radio-group {
  margin-left: 0.2rem;
  display: flex;
  /* 
        设置flex是否换行
          nowrap: 不换行(默认)
          wrap: 开启换行，子元素放不下了，就会换行
      */
  flex-wrap: wrap;
  /* 
        align-items: 调整侧轴的对齐方式（单行模式，不换行）
        align-content: 调整侧轴的对齐方式（多行的模式） 
          和justify-content一样： flex-start flex-end center space-around space-between
      */
  align-content: space-between;
  // justify-content: space-between;
}
.flooter {
  width: 80%;
  margin: 0 auto;
  margin-top: 1rem; /* 50/50 */
  margin-bottom: 1rem; /* 50/50 */
}
</style>


