<template>
  <div class="hcre_quire">
    <phoneHander></phoneHander>
    <div class="bgimage">
      <img src="../assets/img/dzbanner.png" alt="">
    </div>
    <div v-if="show">
      <h4>需求的产品品类</h4>
      <div  class="radios clearfix">
        <van-radio-group v-model="radioChecked" class="clearfix">
          <van-radio v-for="radios in radioList" :key="radios" :name='radios'>
          {{radios}}
          <img
            slot="icon"
            slot-scope="props"
            :src="props.checked ? icon.active : icon.normal"
          >
          </van-radio>
        </van-radio-group>
        <van-field v-model="radiovalue" v-if="radioChecked == '其它'" :error-message="error" placeholder="如果选中其他请输入"  />
      </div>
    </div>
    <!-- 认证需求 -->
    <div v-if="show2">
      <h4>认证需求</h4>
      <div class="whether clearfix"  >
        <van-radio-group v-model="checked">
          <van-radio  v-for="whether in authentication" :key="whether" :name='whether' @click="changeAuthentication(whether)">
            {{whether}}
            <img
              slot="icon"
              slot-scope="props"
              :src="props.checked ? icon.active : icon.normal"
              class="whethers"
            >
          </van-radio>
        </van-radio-group>
        <van-field v-model="value" :disabled='Isitdisabled'   placeholder="如选其它请输入内容" />
      </div>
    </div>
    <!-- 环境需求 -->
    <div class="huang_jing" v-if="show3">
      <h4>环境需求</h4>
      <van-cell-group>
        <p>工作温湿度</p>
        <van-field v-model="value1" label="" :error-message="error3" />
        <p>储存温湿度</p>
        <van-field v-model="value2" label="" :error-message="error4" />
      </van-cell-group>
      <!-- <van-tabs v-model="active" @click="onClick" animated duration='0.5'>
        <van-tab title="工规">   
        </van-tab>
        <van-tab title="商规">
          <van-cell-group>
            <p>工作温湿度</p>
            <van-field v-model="value3" label="" :error-message="error5" />
            <p>储存温湿度</p>
            <van-field v-model="value4" label="" :error-message="error6" />
          </van-cell-group>
        </van-tab>
      </van-tabs> -->
    </div>
    <!-- 防水防尘 -->
    <div class="fang_shui">
      <h4>防水防尘</h4>
      <div class="whether clearfix"  >
        <van-radio-group v-model="checked2">
          <van-radio  v-for="whether in waterproof" :key="whether" :name='whether' @click="changeWaterproof(whether)">
            {{whether}}
            <img
              slot="icon"
              slot-scope="props"
              :src="props.checked ? icon.active : icon.normal"
              class="whethers"
            >
          </van-radio>
        </van-radio-group>
        <span>IP</span>
        <van-field v-model="value5" :disabled='Isitdisabled2' type="digit"  placeholder="请输入2位整数" />
      </div>
      <div class="bgf">
        <h5>OP SHOCK指标</h5>
        <van-field v-model="value6" label=""  />
      </div>
      <div class="bgf">
        <h5>OP-VIBRATION指标</h5>
        <van-field v-model="value7" label=""  />
      </div>
      <div class="bgf">
        <h5>输入电压</h5>
        <van-field v-model="value8" label=""  />
      </div>
      <div class="bgf">
        <h5>其他</h5>
        <van-field v-model="value9" rows="3" autosize type="textarea"/>
      </div>
    </div>
    <!-- 尺寸需求 -->
    <div v-if="show4">
      <h4>尺寸需求</h4>
      <div class="whether clearfix"  >
        <van-radio-group v-model="checked3">
          <van-radio  v-for="whether in sizelist" :key="whether" :name='whether' @click="changeSize(whether)">
            {{whether}}
            <img
              slot="icon"
              slot-scope="props"
              :src="props.checked ? icon.active : icon.normal"
              class="whethers"
            >
          </van-radio>
        </van-radio-group>
        <van-field v-model="size" :disabled='Isitdisabled3'   placeholder="请输入内容" />
      </div>
    </div>
    <div v-if="show5">
      <h4>详细配置需求</h4>
      <van-cell-group>
        <van-field v-model="value10" label="CPU"  :error-message="error7" />
        <van-field v-model="value11" label="内存" :error-message="error8" />
        <van-field v-model="value12" label="存储" :error-message="error9" />
        <van-field v-model="value13" label="网口" :error-message="error10"/>
        <van-field v-model="value14" label="串口" :error-message="error11"/>
        <van-field v-model="value15" label="并口" :error-message="error12"/>
        <van-field v-model="value16" label="USB" :error-message="error13"/>
        <van-field v-model="value17" label="PCI" :error-message="error14"/>
        <van-field v-model="value18" label="PCIe" :error-message="error15"/>
        <van-field v-model="value19" label="光驱" :error-message="error16"/>
        <van-field v-model="value20" label="视频输出接口" :error-message="error17" />
        <van-field v-model="value21" label="WIFI" :error-message="error18"/>
        <van-field v-model="value22" label="Panel" :error-message="error19"/>
        <van-field v-model="value23"  rows="3" autosize type="textarea" label="其他" :error-message="error20"/>
      </van-cell-group>
    </div>
    <div class="flooter">
      <van-button size="large" round type="info" @click="Submit">保存并下一步</van-button>
    </div>
    <!-- v-touch:right="onSwipeRight" 滑动返回上一页 -->
  </div>
</template>
<script>
import phoneHander from "../components/phoneHander";
import fromCreates from "../components/create-form/createform";
export default {
  components: {
    phoneHander,
    fromCreates
  },
  data() {
    return {
      icon: {
        normal: require("../assets/img/noative.png"),
        active: require("../assets/img/ative.png")
      },
      showList: [],
      projectid: "",
      is_status: "",
      radioChecked: "", // 需求的产品品类选中
      radiovalue: "", // 需求的产品品类选中其他输入的内容
      radioList: ["ECI", "ECG", "ECE", "ECP", "ECB", "其它"], // 需求的产品品类选项
      checked: "", // 选中的认证需求
      authentication: ["其他", "ccc"], // 认证需求选项
      value: "", // 认证需求输入的值
      Isitdisabled: true, // 是否禁用认证需求输入框
      checked3: "", // 尺寸选中
      sizelist: ["有要求", "无要求"], // 尺寸
      size: "", // 尺寸输入
      Isitdisabled3: true, // 尺寸
      active: 0, // 环境需求tab
      value1: "Temp: 0℃~40℃; Humidity: 10%~90%", // 工规工作温湿度
      value2: "no special requirement", // 工规储存温湿度
      value3: "Temp: 0℃~40℃; Humidity: 10%~90%", // 商规工作温湿度
      value4: "", // 商规储存温湿度
      waterproof: ["需要", "不需要"], // 防水防尘
      Isitdisabled2: true, // 防水防尘
      checked2: "", // 防水防尘
      value5: "", // 防水防尘
      value6: "no special requirement", // OP SHOCK指标
      value7: "no special requirement", // OP-VIBRATION指标
      value8: "220V", // 输入电压
      value9: "无", // 环境需求其他
      value10: "无特殊要求", // CPU
      value11: "无特殊要求", // 内存
      value12: "无特殊要求", // 存储
      value13: "1", // 网口
      value14: "0", // 串口
      value15: "0", // 并口
      value16: "4", // USB
      value17: "0", // PCI
      value18: "不需要", // PCIe
      value19: "不需要", // 光驱
      value20: "HDMI+DP", // 视频输出接口
      value21: "不需要", // WIFI
      value22: "不需要", // panel
      value23: "无", // 其他
      show: true,
      show2: true,
      show3: true,
      show4: true,
      show5: true,
      error: "",
      error2: "",
      error3: "",
      error4: "",
      error5: "",
      error6: "",
      error7: "",
      error8: "",
      error9: "",
      error10: "",
      error11: "",
      error12: "",
      error13: "",
      error14: "",
      error15: "",
      error16: "",
      error17: "",
      error18: "",
      error19: "",
      error20: "",
      name: "工规"
    };
  },
  created() {
    // this.projectid = localStorage.getItem("projectid");
    this.getShowAndHiden();
    this.projectid = localStorage.getItem("projectid");
    if (this.projectid) {
      this.getEditList();
    }
  },
  computed: {},
  methods: {
    // this.$router.push({ path: "/pbInfoMantion" });
    async getShowAndHiden() {
      const res = await this.axios.get(`/api/frontend/new/template/hardware`);
      if (res.status_code === 200) {
        this.showList = res.data;
        console.log(this.showList);
        if (this.showList[0].is_show == 1) {
          this.show = true;
        } else {
          this.show = false;
        }
        if (this.showList[1].is_show == 1) {
          this.show2 = true;
        } else {
          this.show2 = false;
        }
        if (this.showList[2].is_show == 1) {
          this.show3 = true;
        } else {
          this.show3 = false;
        }
        if (this.showList[3].is_show == 1) {
          this.show4 = true;
        } else {
          this.show4 = false;
        }
        if (this.showList[4].is_show == 1) {
          this.show5 = true;
        } else {
          this.show5 = false;
        }
      }
    },
    async getEditList() {
      const res = await this.axios.get(
        `api/frontend/subject/${this.projectid}/item/hardware`
      );
      console.log(res);
      if (res.status_code === 200) {
        if (res.data.itemable) {
          let list = res.data.itemable;
          this.radioChecked = list.demand_category.checked;
          this.radiovalue = list.demand_category.value;
          if (list.confirm_demand !== "cc") {
            this.value = list.confirm_demand;
            this.checked = '其他'
            this.Isitdisabled = false;
          } else {
            this.checked = list.confirm_demand;
          }
          this.value1 = list.specs.work_humiture;
          this.value2 = list.specs.storage_humiture;
          if (list.prevent !== "不需要") {
            this.checked2 = "需要";
            this.value5 = list.prevent;
            this.Isitdisabled2 = false;
          } else {
            this.checked2 = list.prevent;
          }
          this.value6 = list.OPS_HOCK;
          this.value7 = list.OP_VIBRATION;
          this.value8 = list.voltage;
          this.value9 = list.ambient_other;
          if (list.size === "无要求") {
            this.checked3 = list.size;
          } else {
            this.checked3 = "有要求";
            this.size = list.size;
            this.Isitdisabled3 = false;
          }
          this.value10 = list.cpu;
          this.value11 = list.memory;
          this.value12 = list.storage;
          this.value13 = list.network;
          this.value14 = list.sata;
          this.value15 = list.parallel;
          this.value16 = list.usb;
          this.value17 = list.pci;
          this.value18 = list.pcie;
          this.value19 = list.cd_drive;
          this.value20 = list.video;
          this.value21 = list.wifi;
          this.value22 = list.panel;
          this.value23 = list.dispose_other;
        }
      }
    },
    Submit() {
      if (!this.radioChecked) {
        this.$toast("请选择需求的产品品类");
        return false;
      } else if (this.radioChecked == "其它" && this.radiovalue == "") {
        this.$toast("请输入需求的产品品类");
        this.error = "请输入需求的产品品类";
        return false;
      }
      if (!this.checked) {
        this.$toast("请选择认证需求");
        return false;
      } else if (this.checked == "其他" && this.value == "") {
        this.$toast("请输入认证需求");
        this.error2 = "请输入认证需求";
        return false;
      }
      if (this.value1 === "") {
        this.$toast("请输入工作温湿度");
        this.error3 = "请输入工作温湿度";
        return false;
      }
      if (this.value2 === "") {
        this.$toast("请输入储存温湿度");
        this.error4 = "请输入储存温湿度";
        return false;
      }
      
      if (!this.checked2) {
        this.$toast("请选择防水防尘");
        return false;
      } 
      if (this.checked2 == "需要" && this.value5 == "") {
        this.$toast("请填写防水防尘");
        return false;
      }
      if (!this.value6) {
        this.$toast("请填写OP SHOCK指标");
        return false;
      }
      if (!this.value7) {
        this.$toast("请填写OP-VIBRATION指标");
        return false;
      }
      if (!this.value8) {
        this.$toast("请填写电压");
        return false;
      }
      if (!this.value9) {
        this.$toast("请填写环境需求其他");
        return false;
      }
      if(!this.checked3){
        this.$toast("请选择尺寸需求");
        return false;
      }
      if(this.checked3 == '有需求' && this.size == ''){
        this.$toast("请输入尺寸需求");
        return false;
      }
      if (!this.value10) {
        this.$toast("请填写CPU");
        this.error7 = "请填写CPU";
        return false;
      }
      if (!this.value11) {
        this.$toast("请填写内存");
        this.error8 = "请填写内存";
        return false;
      }
      if (!this.value12) {
        this.$toast("请填写存储");
        this.error9 = "请填写存储";
        return false;
      }
      if (!this.value13) {
        this.$toast("请填写网口");
        this.error10 = "请填写网口";
        return false;
      }
      if (!this.value14) {
        this.$toast("请填写串口");
        this.error11 = "请填写串口";
        return false;
      }
      if (!this.value15) {
        this.$toast("请填写并口");
        this.error12 = "请填写并口";
        return false;
      }
      if (!this.value16) {
        this.$toast("请填写USB");
        this.error13 = "请填写USB";
        return false;
      }
      if (!this.value17) {
        this.$toast("请填写PCI");
        this.error14 = "请填写PCI";
        return false;
      }
      if (!this.value18) {
        this.$toast("请填写PCIe");
        this.error15 = "请填写PCIe";
        return false;
      }
      if (!this.value19) {
        this.$toast("请填写光驱");
        this.error16 = "请填写光驱";
        return false;
      }
      if (!this.value20) {
        this.$toast("请填写视频输出接口");
        this.error17 = "请填写视频输出接口";
        return false;
      }
      if (!this.value21) {
        this.$toast("请填写WIFI");
        this.error18 = "请填写WIFI";
        return false;
      }
      if (!this.value22) {
        this.$toast("请填写Panel");
        this.error19 = "请填写Panel";
        return false;
      }
      if (!this.value23) {
        this.$toast("请填写其他");
        this.error20 = "请填写其他";
        return false;
      }
      this.subject();
    },
    async subject() {
      const res = await this.axios.post(
        `api/frontend/subject/${this.projectid}/item/hardware`,
        {
          demand_category: {
            checked: this.radioChecked,
            value: this.radiovalue || ''
          },
          confirm_demand: this.value || this.checked,
          specs: {
            work_humiture: this.value1 || this.value3,
            storage_humiture: this.value2 || this.value4
          },
          prevent: this.value5 || this.checked2,
          OPS_HOCK: this.value6,
          OP_VIBRATION: this.value7,
          voltage: this.value8,
          ambient_other: this.value9,
          size: this.size || this.checked3,
          cpu: this.value10,
          memory: this.value11,
          storage: this.value12,
          network: this.value13,
          sata: this.value14,
          parallel: this.value15,
          usb: this.value16,
          pci: this.value17,
          pcie: this.value18,
          cd_drive: this.value19,
          video: this.value20,
          wifi: this.value21,
          panel: this.value22,
          dispose_other: this.value23,

        }
      );
      console.log(res);
      if (res.status_code === 200) {
        this.$router.push({ path: "/pbInfoMantion" });
      }
    },
    // 环境需求点击tab
    onClick() {
      console.log(this.active);
      if (this.active == 0) {
        // 工规
        this.value3 = "Temp: 0℃~40℃; Humidity: 10%~90%";
        this.value4 = "";
        this.error5 = "";
        this.error6 = "";
        this.name = "工规";
      } else {
        // 商规
        this.value1 = "Temp: 0℃~40℃; Humidity: 10%~90%";
        this.value2 = "";
        this.error3 = "";
        this.error4 = "";
        this.name = "商规";
      }
    },
    // 认证需求
    changeAuthentication(checke) {
      if (checke === "其他") {
        this.Isitdisabled = false;
      } else {
        this.Isitdisabled = true;
        this.value = "";
      }
    },
    // 防水防尘
    changeWaterproof(check) {
      if (check === "需要") {
        this.Isitdisabled2 = false;
        this.value5 = "";
      } else {
        this.Isitdisabled2 = true;
        this.value5 = "";
      }
    },
    // 尺寸需求
    changeSize(check) {
      console.log(check);
      if (check === "有要求") {
        this.Isitdisabled3 = false;
        this.size = "长*宽*高";
      } else {
        this.Isitdisabled3 = true;
        this.size = "";
      }
    },
    // 用于编辑
    getQueryValue(name) {
      var url = window.location.href;
      var result = url.match(new RegExp("[?&]" + name + "=([^&]+)", "i"));
      if (result == null || result.length < 1) {
        return "";
      }
      return decodeURI(result[1]);
    }
  }
};
</script>

<style lang="less" scoped>
.hcre_quire {
  /deep/.van-field__control {
    border: 1px solid #cccccc;
  }
  h4 {
    font-size: 0.62rem /* 26/50 */;
    margin-top: 1.18rem /* 59/50 */;
    margin-bottom: 0.46rem /* 23/50 */;
    margin-left: 0.6rem /* 30/50 */;
    color: #000;
    font-weight: 600;
  }
  h5 {
    font-size: 0.52rem; /* 26/50 */
    color: #b1b7c0;
    margin-left: 0.6rem; /* 40/50 */
  }
  .bgf {
    background-color: #fff;
    padding-bottom: 0.4rem /* 20/50 */;
    .van-cell {
      width: 90%;
      border: 1px solid #dde5eb;
      margin-left: 0.5rem /* 25/50 */;
    }
  }
  .fang_shui {
    background-color: #fff;
    span {
      position: absolute;
      top: 1.2rem;
      left: 3rem /* 50/50 */;
      z-index: 999;
      font-size: 16px;
    }
  }
  /deep/.van-radio__icon {
    height: 1.58rem /* 79/50 */;
    width: 2.6rem /* 130/50 */;
    img {
      height: 1.58rem /* 79/50 */;
      width: 2.6rem /* 130/50 */;
    }
  }
  .van-radio {
    width: 2.6rem /* 130/50 */;
    position: relative;
    // float: left;
    margin-right: 0.3rem /* 25/50 */;
    margin-bottom: 0.5rem; /* 25/50 */
  }
  /deep/.van-radio__label {
    position: absolute;
    margin-left: 0;
    left: 50%;
    transform: translate(-50%);
    width: 100%;
    text-align: center;
  }
  .radios {
    // padding-left: 0.4rem /* 20/50 */;
    padding-top: 0.4rem /* 20/50 */;
    // padding-bottom: 0.4rem /* 20/50 */;
    background-color: #fff;
  }
  .van-radio-group {
    margin-left: 0.2rem;
    display: flex;
    /* 
        设置flex是否换行
          nowrap: 不换行(默认)
          wrap: 开启换行，子元素放不下了，就会换行
      */
    flex-wrap: wrap;
    /* 
        align-items: 调整侧轴的对齐方式（单行模式，不换行）
        align-content: 调整侧轴的对齐方式（多行的模式） 
          和justify-content一样： flex-start flex-end center space-around space-between
      */
    align-content: space-between;
    // justify-content: space-between;
  }
  .checkedbox {
    padding-top: 0.4rem /* 20/50 */;
    background-color: #fff;
    .bgbox {
      margin-bottom: 0.4rem /* 20/50 */;
    }
    .span1 {
      margin-left: 0.2rem /* 10/50 */;
      font-size: 0.52rem; /* 26/50 */
      margin-bottom: 0.4rem /* 20/50 */;
    }
    .span2 {
      font-size: 0.52rem /* 26/50 */;
      color: #408bea;
      background-color: #dfefff;
      margin-left: 0.2rem /* 10/50 */;
      margin-bottom: 0.4rem /* 20/50 */;
    }
  }
  .van-checkbox-group {
    margin-left: 0.2rem;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    .van-checkbox {
      width: 2.6rem /* 130/50 */;
      position: relative;
      margin-right: 0.3rem /* 25/50 */;
      margin-bottom: 0.5rem;
      /deep/.van-checkbox__icon {
        height: 1.58rem /* 79/50 */;
        width: 2.6rem /* 130/50 */;
        img {
          height: 1.58rem /* 79/50 */;
          width: 2.6rem /* 130/50 */;
        }
      }
      /deep/.van-checkbox__label {
        position: absolute;
        margin-left: 0;
        left: 50%;
        transform: translate(-50%);
        width: 100%;
        text-align: center;
      }
    }
  }
  .whether {
    background-color: #fff;
    padding-top: 1rem; /* 50/50 */
    position: relative;
    .van-radio:nth-child(2) {
      width: 100%;
      /deep/.van-radio__icon {
        width: 100%;
        img {
          width: 100%;
        }
      }
    }
    .van-cell {
      position: absolute;
      width: 80%;
      top: 0.8rem;
      left: 3rem;
    }
    .wher_ip {
      position: absolute;
      top: 1.4rem /* 50/100 */;
      left: 3rem /* 40/100 */;
    }
  }
  .Price_range {
    // padding-left: 0.4rem /* 20/50 */;
    padding-top: 0.8rem /* 40/50 */;
    padding-bottom: 0.4rem /* 20/50 */;
    background-color: #fff;
    .van-cell-group {
      display: flex;
      flex-wrap: wrap;
      align-content: space-between;
      line-height: 1.5rem /* 100/50 */;
      .van-cell {
        width: 44%;
      }
    }
  }
  /deep/.van-tabs__line {
    background-color: #408bea;
    width: 50%;
    top: 0;
  }
  /deep/.van-cell-group {
    overflow: hidden;
    padding-bottom: 0.6rem; /* 20/50 */
  }
  /deep/.van-tab {
    background-color: #f4f7fa;
  }
  /deep/.van-tab--active {
    background-color: #fff;
  }
  .Time_zone {
    p {
      font-size: 0.52rem /* 26/50 */;
      padding-top: 1.18rem /* 59/50 */;
      padding-bottom: 0.46rem /* 23/50 */;
      padding-left: 0.6rem /* 30/50 */;
      color: #8b919a;
      background-color: #fff;
    }
  }

  .flooter {
    width: 80%;
    margin: 0 auto;
    margin-top: 1rem; /* 50/50 */
    margin-bottom: 1rem; /* 50/50 */
  }
  .projec_text {
    margin-left: 0.6rem; /* 10/100 */
    margin-top: 0.2rem; /* 20/100 */
    font-size: 0.42rem; /* 22/100 */
    color: #aab2b8;
  }
  .huang_jing {
    /deep/.van-field__control {
      border: none;
    }
    p {
      margin-left: 1rem; /* 10/50 */
      margin-bottom: 0.4rem;
      margin-top: 0.4rem;
    }
    .van-cell {
      height: 2.5rem;
      width: 85%;
      border: 1px solid #dde5eb;
      margin-left: 1rem; /* 50/50 */
    }
  }
}
</style>

