<template>
  <div class="Presale-see">
    <!-- 售前查看反馈 -->
    <p v-if="Confirmfeedback" class="Tips">(非必填)</p>
    <van-field
      v-model="message"
      type="textarea"
      placeholder="请输入说明"
      rows="3"
      autosize
      v-if="Confirmfeedback"
    />
    <button class="supplement" v-if="Confirmfeedback" @click="Submitcopy">方案已提交给客户</button>

    <!-- 用户认可或者修改方案 -->
    <van-tabs v-model="actives" v-if="approval">
      <van-tab title="客户初步认可方案">
        <van-field
          v-model="approvaltext"
          type="textarea"
          placeholder="请输入说明(非必填)"
          rows="3"
          autosize
        />
      </van-tab>
      <van-tab title="方案需要修改">
        <van-field
          v-model="approvaltextow"
          type="textarea"
          placeholder="请输入说明（必填）"
          rows="3"
          autosize
        />
      </van-tab>
    </van-tabs>
    <button class="supplement" v-if="approval" @click="submitted">提交</button>

    <!-- 售前已成单 -->
    <van-cell-group v-if="orderform">
      <van-field v-model="number" label='成单数量(必填)' class="input_m" />
      <!-- <van-field v-model="dates" label='成单日期' /> -->
      <van-cell title="成单日期(必选)" is-link :value="dates" @click="SelectDate"/>
      <van-popup v-model="showData" round position="bottom" :style="{ height: '60%' }" >
        <van-datetime-picker
          v-model="currentDate"
          type="date"
          title="选择年月日"
          @confirm="dateConfirm"
        />
      </van-popup>
      <p>（非必填）</p>
      <van-field
        v-model="message"
        type="textarea"
        placeholder="请输入说明(非必填)"
        rows="3"
        autosize
      />
    </van-cell-group>
    <button class="supplement" v-if="orderform" @click="completion">确认已成单</button>

    <!-- 搁置和移交他人 -->
    <div class="btnlist">
      <button @click="shelve">搁置项目</button>
      <button @click="clickCopy">复制到草搞</button>
      <button @click="TransferToOthers">移交他人</button>
    </div>
    <van-field
      v-model="message"
      type="textarea"
      :placeholder="isplaceholder"
      rows="3"
      autosize
      v-show="show"
    />
    <!-- 移交 -->
    <van-cell-group v-show="yijiao">
      <h2 class="text-title">请输入被移交人（售前）的邮箱（必填）</h2>
      <van-field v-model="mailbox" placeholder="邮箱" />
      <van-field v-model="reason" rows="3" type="textarea" placeholder="请输入移交项目的理由（非必填）" />
    </van-cell-group>
    <button class="supplement" @click="changeSupp" v-show="btnShow">补充说明</button>
    <!-- 补充说明的提交 -->
    <button class="supplement"  v-show="Submit" @click="subShuoMing">提交</button>
    <button class="supplement"  v-show="isShelve" @click="subGeZhi">确认搁置项目</button>
    <button class="supplement"  v-show="yijiao">确认移交项目</button>
  </div>
</template>
<script>
export default {
  data() {
    return {
      message: "",
      approvaltextow: "",
      approvaltext: "",
      show: false,
      btnShow: false,
      Submit: false,
      isShelve: false,
      Confirmfeedback: false, // 确认反馈
      approval: false, // 用户初步认可方案
      orderform: false, // 成单
      islabel: "说明",
      isplaceholder: "输入您需要补充的信息...",
      mailbox: "",
      reason: "",
      yijiao: false,
      actives: 0,
      dates: "", //成单日期
      number: "", // 成单数量
      projectid: "",
      showData: false,
      currentDate: new Date()
    };
  },
  props: ["status"],
  created() {
    console.log(this.status);
    this.projectid = localStorage.getItem("projectid");
    if (this.status === "pre_sale_draft_confirm") {
      this.Confirmfeedback = true;
    } else if (this.status === "pre_sale_draft_confirm_submit") {
      this.approval = true;
    } else if (this.status === "pre_sale_confirm_programme") {
      this.orderform = true;
    }
  },
  methods: {
    // 选择日期
    SelectDate() {
      this.showData = true;
    },
    // 确认选择日期
    dateConfirm(value) {
      this.dates = this.moment(value).format("YYYY-MM-DD");
      this.showData = false;
    },
    changeSupp() {
      this.show = true;
      this.btnShow = false;
      this.Submit = true;
    },
    shelve() {
      console.log("搁置项目");
      this.show = true;
      this.isShelve = true;
      this.Submit = false;
      this.btnShow = false;
      this.yijiao = false;
      this.isplaceholder = "请输入说明内容";
    },
    TransferToOthers() {
      this.show = false;
      this.isShelve = false;
      this.Submit = false;
      this.btnShow = false;
      this.yijiao = true;
    },
    // 提交文案
    Submitcopy() {
      this.Submitcopyapi();
    },
    async Submitcopyapi() {
      const res = await this.axios.post(
        `/api/frontend/subject/${this.projectid}/operation/psConfirm`,
        {
          explain: this.message
        }
      );
      if (res.status_code == 200) {
        this.$toast("提交成功");
        this.$router.push({ path: "/news" });
      } else {
        this.$toast(res.error);
      }
    },
    // 用户初步认可或者修改方案
    async submitted() {
      let status = "";
      let text = "";
      if (this.actives == 0) {
        // 用户初步同意
        status = "translateAdopt";
        text = this.approvaltext;
        this.submittedapi(text, status);
      } else {
        // 方案需要修改
        status = "translateReject";
        if(this.approvaltextow == ''){
          this.$toast('请填写修改说明')
          return false
        }
        text = this.approvaltextow;
        this.submittedapi(text, status);
      }
    },
    async submittedapi(text, status) {
      const res = await this.axios.post(
        `/api/frontend/subject/${this.projectid}/operation/${status}`,
        {
          explain: text
        }
      );
      if (res.status_code === 200) {
        this.$toast("提交成功");
        this.$router.push({ path: "/news" });
      } else {
        this.$toast(res.error);
      }
    },
    // 成单
    completion() {
      if(!this.number){
        this.$toast('请填写成单数量')
        return false
      }
      if(!this.dates){
        this.$toast('请选择成单日期')
        return false
      }
      this.completionapi();
    },
    async completionapi() {
      const res = await this.axios.post(
        `/api/frontend/subject/${this.projectid}/operation/complete`,
        {
          complete_num: this.number,
          complete_tine: this.dates,
          explain: this.message
        }
      );
      if (res.status_code === 200) {
        this.$toast("提交成功");
        this.$router.push({ path: "/news" });
      } else {
        this.$toast(res.error);
      }
    },
    // 移交
    subShuoMing() {
      this.changeTransferapi();
    },
    async changeTransferapi() {
      const res = await this.axios.post(
        `/api/frontend/subject/${this.projectid}/operation/changeSubject`,
        {
          email: this.mailbox,
          explain: this.reason
        }
      );
      if (res.status_code === 200) {
        this.$toast("移交成功");
        this.$router.push({ path: "/news" });
      } else {
        this.$toast(res.error);
      }
    },
    // 搁置
    subGeZhi() {
      this.changeShelveapi();
    },
    async changeShelveapi() {
      const res = await this.axios.post(
        `/api/frontend/subject/${this.projectid}/operation/shelve`,
        {
          explain: this.message
        }
      );
      if (res.status_code === 200) {
        this.$router.push({ path: "/projectlist" });
      } else {
        this.$toast(res.error);
      }
    },
    async clickCopy() {
      const res = await this.axios.post(
        `/api/frontend/subject/copy/${this.projectid}`
      );
      if (res.status_code === 200) {
        this.$toast("复制成功,请到申请列表查看项目");
      } else {
        this.$toast(res.error);
      }
    }
  }
};
</script>

<style lang="less" scoped>
@jianbian: linear-gradient(90deg, #09aaff, #2685ff);
.Presale-see {
  .Tips{
    text-align: center;
  }
  padding-bottom: 2rem /* 100/50 */;
  .btnlist {
    width: 100%;
    padding-left: 0.56rem /* 28/50 */;
    padding-right: 0.56rem /* 28/50 */;
    margin-bottom: 1rem /* 50/50 */;
    display: flex;
    justify-content: space-between;
    margin-top: 3.2rem /* 60/100 */;
    button {
      width: 31%;
      height: 1.7rem /* 85/50 */;
      border-radius: 0.86rem /* 43/50 */;
      background: @jianbian;
      color: #fff;
    }
  }
  .supplement {
    width: 13.82rem /* 691/50 */;
    height: 1.7rem /* 85/50 */;
    background: @jianbian;
    border-radius: 0.86rem /* 43/50 */;
    color: #fff;
    margin-top: 0.8rem /* 40/50 */;
    margin-bottom: 0.8rem /* 40/50 */;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
  }
  .text-title {
    color: #8b919a;
    text-align: left;
    padding-left: 0.5rem /* 25/50 */;
    padding-top: 0.4rem /* 20/50 */;
  }
  /deep/.van-tabs__line {
    width: 50%;
  }
  /deep/.van-field__control {
    border: 1px solid #8b919a;
    // height: 3rem /* 50/100 */ !important;
    padding-left: 0.5rem;
  }
  /deep/.van-field__label{
    width: 4.2rem;
  }
  
}
</style>
