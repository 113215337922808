<template>
  <div>
    <div class="muokuan" v-for="item in list"  :key="item.fid" >
      <template >
        <h2>{{item.title}}</h2>
        <ul>
          <li v-for="child in item.children" :key="child.cid">
            <!-- 有table切换得模板  animated开启专场动画  duration设置动画时间-->
            <van-tabs v-if="item.tableList.length > 0" animated duration='0.5' sticky @click="changeTabs(item)">
              <van-tab v-for="table in item.tableList" :title="table.name" :key="table.tabId">
                <ul>
                  <!-- 遍历出table中得表单数据 -->
                  <li v-for="tabChild in table.children" :key="tabChild.tcid" >
                    <template v-if="tabChild.sonlist.length <= 0">
                      <!-- 宽度为100%的表单  tabChild.pattern == 'input'-->
                      <van-cell-group v-if="tabChild.pattern == 'input'">
                        <van-field v-model="tabChild.value" :placeholder="tabChild.name" :type="tabChild.type" />
                      </van-cell-group>
                      <!-- 左右结构的表单 tabChild.pattern == 'leftinput'-->
                      <van-cell-group v-if="tabChild.pattern == 'leftinput'">
                        <van-field
                          v-model="tabChild.value"
                          :label="tabChild.name"
                          :placeholder="tabChild.placeholder"
                          :type="tabChild.type"
                        />
                      </van-cell-group>
                      <!-- 标题在上面的表单 tabChild.pattern == 'topinput' -->
                      <van-cell-group v-if="tabChild.pattern == 'topinput'" class="top_input">
                        <div class="inptext">{{tabChild.name}}</div>
                        <van-field
                          v-model="tabChild.value"
                          :placeholder="tabChild.placeholder"
                          :type="tabChild.type"
                        />
                      </van-cell-group>
                      <!-- 文本域 -->
                      <van-cell-group v-if="tabChild.pattern == 'textarea'">
                        <div class="inptext">{{tabChild.name}}</div>
                        <van-field
                          v-model="tabChild.value"
                          :placeholder="tabChild.placeholder"
                          type="textarea"
                          autosize
                        />
                      </van-cell-group>
                      <!-- 单选框  -->
                      <div class="radios clearfix" v-if="tabChild.pattern == 'radio'">
                        <van-radio-group v-model="tabChild.checked" class="clearfix">
                          <van-radio v-for="radios in tabChild.radioList" :key="radios" :name='radios'>
                          {{radios}}
                          <img
                            slot="icon"
                            slot-scope="props"
                            :src="props.checked ? icon.active : icon.normal"
                          >
                          </van-radio>
                          </van-radio-group>
                          <van-cell-group>
                          <van-field v-model="tabChild.value"  v-if="tabChild.checked == '其他'"  placeholder="如果选其他请输入"  />
                        </van-cell-group>
                      </div>
                      <!-- 复选框 checkebox -->
                      <div class="checkedbox" v-if="tabChild.pattern == 'checkbox'">
                        <div class="bgbox">
                          <span class="span1">已选:</span>
                          <span class="span2" v-for="isSelect in tabChild.result" :key="isSelect">{{isSelect}}</span>
                        </div>
                        <van-checkbox-group v-model="tabChild.result">
                          <van-checkbox
                            v-for="checkbox in tabChild.list"
                            :key="checkbox"
                            :name="checkbox"
                            @click="changeCheck(tabChild)"
                          >
                          {{checkbox}}
                            <img
                              slot="icon"
                              slot-scope="props"
                              :src="props.checked ? icon.active : icon.normal"
                            >
                          </van-checkbox>
                        </van-checkbox-group>
                        <van-field v-model="tabChild.value"  placeholder="如果选中其他请输入" v-if="tabChild.isshow" />
                      </div>
                      <!-- 是否填写 -->
                      <div class="whether " v-if="tabChild.pattern == 'whether'">
                        <div class="whether wetm clearfix">
                         <h2>{{tabChild.name}}</h2> 
                          <van-radio-group v-model="tabChild.checked">
                            <van-radio  v-for="whether in tabChild.radioList" :key="whether" :name='whether'>
                              {{whether}}
                              <img
                                slot="icon"
                                slot-scope="props"
                                :src="props.checked ? icon.active : icon.normal"
                                class="whethers"
                              >
                            </van-radio>
                          </van-radio-group>
                          <van-field v-model="tabChild.value"  placeholder="请输入内容" />
                        </div>
                      </div>
                      <!-- 价格区间 -->
                      <div class="Price_range" v-if="tabChild.pattern == 'pricerange'">
                        <h2>{{tabChild.name}}</h2>
                        <van-cell-group>
                          <van-field v-model="tabChild.value[0].val" :placeholder="tabChild.value[0].placeholder" />
                          <span>至</span>
                          <van-field v-model="tabChild.value[1].val" :placeholder="tabChild.value[1].placeholder" />
                        </van-cell-group>
                      </div>
                      <!-- 时间选择 -->
                      <div class="Time_zone" v-if="tabChild.pattern == 'timezone'">
                        <van-cell v-for="time in tabChild.timeValue" :key="time.id" @click="changeTime(item,time.id)":title="tabChild.name" :value="time.value " clickable is-link />
                      </div>
                      <!-- 单选按钮 -->
                      <div class="ele-radio" v-if='tabChild.pattern == "elementradio"' >
                        <el-radio-group v-model="tabChild.checked">
                          <h2>{{tabChild.name}}</h2>
                          <el-radio :label="eleradio" v-for="eleradio in tabChild.radioList" :key="eleradio">{{eleradio}}</el-radio>
                        </el-radio-group>
                      </div>
                    </template>
                    <!-- table下的模块 -->
                    <template v-if="tabChild.sonlist.length > 0">
                      <h2>{{tabChild.sontitle}}</h2>
                      <ul>
                        <li v-for="sonlis in tabChild.sonlist" :key="sonlis.sonid">
                          <van-cell-group v-if="sonlis.pattern == 'input'">
                            <van-field v-model="sonlis.value" :placeholder="'请输入' +sonlis.placeholder" :type="sonlis.type" />
                          </van-cell-group>
                          <!-- 标题在上面的表单 tabChild.pattern == 'topinput' -->
                          <van-cell-group v-if="sonlis.pattern == 'topinput'" class="top_input">
                            <div class="inptext">{{sonlis.name}}</div>
                            <van-field
                              v-model="sonlis.value"
                              :placeholder="sonlis.placeholder"
                              :type="sonlis.type"
                            />
                          </van-cell-group>
                          <!-- 左右结构的表单 tabChild.pattern == 'leftinput'-->
                          <van-cell-group v-if="sonlis.pattern == 'leftinput'">
                            <van-field
                              v-model="sonlis.value"
                              :label="sonlis.name"
                              :placeholder="sonlis.placeholder"
                              :type="sonlis.type"
                            />
                          </van-cell-group>
                          <!-- 文本域 -->
                          <van-cell-group v-if="sonlis.pattern == 'textarea'">
                            <div class="inptext">{{sonlis.name}}</div>
                            <van-field
                              v-model="sonlis.value"
                              :placeholder="sonlis.placeholder"
                              type="textarea"
                              autosize
                            />
                          </van-cell-group>
                          <!-- 单选框  -->
                          <div class="radios clearfix" v-if="sonlis.pattern == 'radio'">
                            <van-radio-group v-model="sonlis.checked" class="clearfix">
                              <van-radio v-for="radios in sonlis.radioList" :key="radios" :name='radios'>
                              {{radios}}
                                <img
                                  slot="icon"
                                  slot-scope="props"
                                  :src="props.checked ? icon.active : icon.normal"
                                >
                              </van-radio>
                              </van-radio-group>
                              <van-cell-group>
                              <van-field v-model="sonlis.value"  v-if="sonlis.checked == '其他'"  placeholder="如果选其他请输入"  />
                            </van-cell-group>
                          </div>
                          <!-- 复选框 checkebox -->
                          <div class="checkedbox" v-if="sonlis.pattern == 'checkbox'">
                            <div class="bgbox">
                              <span class="span1">已选:</span>
                              <span class="span2" v-for="isSelect in sonlis.result" :key="isSelect">{{isSelect}}</span>
                            </div>
                            <van-checkbox-group v-model="sonlis.result">
                              <van-checkbox
                                v-for="checkbox in sonlis.list"
                                :key="checkbox"
                                :name="checkbox"
                                @click="changeCheck(sonlis)"
                              >
                              {{checkbox}}
                                <img
                                  slot="icon"
                                  slot-scope="props"
                                  :src="props.checked ? icon.active : icon.normal"
                                >
                              </van-checkbox>
                            </van-checkbox-group>
                            <van-field v-model="sonlis.value"  placeholder="如果选中其他请输入" v-if="sonlis.isshow" />
                          </div>
                          <!-- 是否填写 -->
                          <div class="whether wetm" v-if="sonlis.pattern == 'whether'">
                            <h2>{{sonlis.name}}</h2>
                            <!-- <div class="whether clearfix"  v-for="check in sonlis.childer" :key="check.cid">
                              <van-radio-group v-model="check.checked">
                                <van-radio  v-for="whether in check.radioList" :key="whether" :name='whether'>
                                  {{whether}}
                                  <img
                                    slot="icon"
                                    slot-scope="props"
                                    :src="props.checked ? icon.active : icon.normal"
                                    class="whethers"
                                  >
                                </van-radio>
                              </van-radio-group>
                              <van-field v-model="check.value"  placeholder="请输入内容" />
                            </div> -->
                            <van-radio-group v-model="sonlis.checked">
                              <van-radio  v-for="whether in sonlis.radioList" :key="whether" :name='whether'>
                                {{whether}}
                                <img
                                  slot="icon"
                                  slot-scope="props"
                                  :src="props.checked ? icon.active : icon.normal"
                                  class="whethers"
                                >
                              </van-radio>
                            </van-radio-group>
                            <van-field v-model="tabChild.value"  placeholder="请输入内容" />
                          </div>
                          <!-- 价格区间 -->
                          <div class="Price_range" v-if="sonlis.pattern == 'pricerange'">
                            <h2>{{sonlis.title}}</h2>
                            <van-cell-group v-for="pricer in sonlis.childer" :key="pricer.cid">
                              <van-field v-model="pricer.value[0].val" :placeholder="pricer.value[0].placeholder" />
                              <span>至</span>
                              <van-field v-model="pricer.value[1].val" :placeholder="pricer.value[1].placeholder" />
                            </van-cell-group>
                          </div>
                          <!-- 时间选择 -->
                          <div class="Time_zone" v-if="sonlis.pattern == 'timezone'">
                            <van-cell v-for="time in sonlis.timeValue" :key="time.id" @click="changeTime(item,time.id)":title="sonlis.name" :value="time.value " clickable is-link />
                          </div>
                          <!-- 单选列 -->
                          <div class="ele-radio" v-if='sonlis.pattern == "elementradio"' >
                            <el-radio-group v-model="sonlis.checked">
                              <h2>{{sonlis.name}}</h2>
                              <el-radio :label="eleradio" v-for="eleradio in sonlis.radioList" :key="eleradio">{{eleradio}}</el-radio>
                            </el-radio-group>
                          </div>
                        </li>
                      </ul>
                    </template>
                  </li>
                </ul>
              </van-tab>
            </van-tabs>
            <!-- 没有table切换得模板  tableList中没有数据-->
            <div v-if="item.tableList.length <= 0">
              <!-- 宽度为100%的表单 -->
              <van-cell-group v-if="child.pattern == 'input'">
                <van-field v-model="child.value" :placeholder="'请输入'+child.name" :type="child.type" />
              </van-cell-group>
              <!-- 左右结构的表单 tabChild.pattern == 'leftinput'-->
              <van-cell-group v-if="child.pattern == 'leftinput'">
                <van-field
                  v-model="child.value"
                  :label="child.name"
                  :placeholder="child.placeholder"
                  :type="child.type"
                />
              </van-cell-group>
              <!-- 标题在上面的表单 tabChild.pattern == 'topinput' -->
              <van-cell-group v-if="child.pattern == 'topinput'">
                <div class="inptext">{{child.name}}</div>
                <van-field
                  v-model="child.value"
                  :placeholder="child.placeholder"
                  :type="child.type"
                />
              </van-cell-group>
              <!-- 文本域 -->
              <van-cell-group v-if="child.pattern == 'textarea'">
                <div class="inptextarea">{{child.name}}</div>
                <van-field
                  v-model="child.value"
                  :placeholder="child.placeholder"
                  type="textarea"
                   autosize
                />
              </van-cell-group>
              <!-- 单选框 -->
              <div v-if="child.pattern == 'radio'" class="radios clearfix">
                <van-radio-group v-model="child.checked" class="clearfix">
                  <van-radio v-for="radios in child.radioList" :key="radios" :name='radios'>
                  {{radios}}
                  <img
                    slot="icon"
                    slot-scope="props"
                    :src="props.checked ? icon.active : icon.normal"
                  >
                  </van-radio>
                </van-radio-group>
                <van-field v-model="child.value"  v-if="child.checked == '其他'"  placeholder="如果选中其他请输入"  />
              </div>
              <!-- 复选框 -->
              <div class="checkedbox" v-if="child.pattern == 'checkbox'">
                <div class="bgbox">
                  <span class="span1">已选:</span>
                  <span class="span2" v-for="isSelect in child.result" :key="isSelect">{{isSelect}}</span>
                </div>
                <van-checkbox-group v-model="child.result">
                  <van-checkbox
                    v-for="checkbox in child.list"
                    :key="checkbox"
                    :name="checkbox"
                    @click="changeCheck(child)"
                  >
                  {{checkbox}}
                    <img
                      slot="icon"
                      slot-scope="props"
                      :src="props.checked ? icon.active : icon.normal"
                    >
                  </van-checkbox>
                </van-checkbox-group>
                <van-field v-model="child.value"  placeholder="如果选中其他请输入" v-if="child.isshow" />
              </div>
              <!-- 是否填写 -->
              <div class="whether clearfix" v-if="child.pattern == 'whether'" >
                <van-radio-group v-model="child.checked">
                  <van-radio  v-for="whether in child.radioList" :key="whether" :name='whether' @click="changeWheth(child)">
                    {{whether}}
                    <img
                      slot="icon"
                      slot-scope="props"
                      :src="props.checked ? icon.active : icon.normal"
                      class="whethers"
                    >
                  </van-radio>
                </van-radio-group>
                <van-field v-model="child.value"  placeholder="请输入内容" v-if="!child.isshow"/>
                <!-- 名字一改直接失效 -->
                <span class="wher_ip" v-if="item.title == '防水防尘' && !child.isshow" >IP:</span>
              </div>
              <!-- 价格区间 -->
              <div class="Price_range" v-if="child.pattern == 'pricerange'">
                <!-- <h2>{{child.name}}</h2> -->
                <van-cell-group>
                  <van-field   v-model="child.value[0].value"/>
                  <span>至</span>
                  <van-field  v-model="child.value[1].value" />
                </van-cell-group>
              </div>
              <!-- 时间选择 -->
              <div class="Time_zone" v-if="child.pattern == 'timezone'">
                <van-cell v-for="time in child.timeValue" :key="time.id" @click="changeTime(item,time.id,child)" :title="child.name" :value="time.value " clickable is-link />
                <!-- <van-cell @click="changeTime" title="批量交付时间" value="2020-10-30" clickable is-link /> -->
              </div>
              <!-- 单选列 -->
              <div class="ele-radio" v-if='child.pattern == "elementradio"' >
                <el-radio-group v-model="child.checked">
                  <h2>{{child.name}}</h2>
                  <el-radio :label="eleradio" v-for="eleradio in child.radioList" :key="eleradio">{{eleradio}}</el-radio>
                </el-radio-group>
              </div>
            </div>
            <!-- ************* -->
          </li>
        </ul>
      </template>
    </div>
    <van-popup v-model="show" position="bottom" :overlay="true">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        confirm-button-text="确认选择"
        @confirm="tiemDetermine"
        :formatter="formatter"
      />
    </van-popup>
    <div class="flooter">
      <van-button size="large" round type="info" @click="fn">保存并下一步</van-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      radio: 1,
      checked: true,
      datetime: "",
      timeid: "",
      currentDate: new Date(),
      show: false,
      Parentid: 0, // 选择时间时
      // 自定义的radio的图标
      icon: {
        normal: require("../../assets/img/noative.png"),
        active: require("../../assets/img/ative.png")
      },
      cid: ""
    };
  },
  props: {
    list: Array
  },
  methods: {
    fn() {
      // 吧数据传给父组件
      // this.$router.push({ path: "/pbInfoMantion" });
      console.log(this.list);
      this.$emit("get", this.list);
    },
    changeCheck(list) {
      console.log(list);
      if (list.result.indexOf("其他") !== -1) {
        list.isshow = true;
      } else {
        list.isshow = false;
      }
    },
    changeWheth(child) {
      if (child.checked == "其他" || child.checked == "ccc") {
        if (child.checked.indexOf("其他") !== -1) {
          console.log("显示");
          this.$set(child, "isshow", false);
        } else {
          console.log("隐藏");
          this.$set(child, "isshow", true);
        }
      }
      if (child.checked == "需要" || child.checked == "不需要") {
        if (child.checked.indexOf("需要") == 0) {
          this.$set(child, "isshow", false);
        } else {
          this.$set(child, "isshow", true);
        }
      }
      if (child.checked == "是" || child.checked == "否") {
        if (child.checked.indexOf("是") !== -1) {
          this.$set(child, "isshow", false);
        } else {
          this.$set(child, "isshow", true);
        }
      }
      if (child.checked == "确定数量" || child.checked == "不确定数量") {
        if (child.checked.indexOf("确定数量") == 0) {
          this.$set(child, "isshow", false);
        } else {
          this.$set(child, "isshow", true);
        }
      }
      console.log(child.checked.indexOf("确定数量"));
    },
    // 切换tab
    changeTabs(item) {
      if (item.title == "工作温湿度") {
        item.tableList.forEach(val => {
          val.children.forEach(leve => {
            leve.value = "";
          });
        });
      }
    },
    changeTime(item, timeid, child) {
      this.show = true;
      this.Parentid = item.fid;
      console.log(item);
      this.timeid = timeid;
      if (child) {
        this.cid = child.cid;
      }
    },
    tiemDetermine(value) {
      console.log(value);
      let year = value.getFullYear();
      let month = value.getMonth() + 1; // 月份从0开始
      let day = value.getDate(); // 日期
      function addZero(n) {
        return n < 10 ? "0" + n : n;
      }
      this.datetime = year + "-" + addZero(month) + "-" + addZero(day);
      this.list.forEach(item => {
        console.log(item.fid, this.Parentid);

        if (item.fid == this.Parentid) {
          // 判断tablelist 是否有值 有值需要遍历table
          if (item.tableList.length <= 0) {
            item.children.forEach(val => {
              if (val.cid === this.cid) {
                val.timeValue.forEach(lab => {
                  if (lab.id == this.timeid) {
                    lab.value = this.datetime;
                  }
                });
              }
            });
          } else {
            item.tableList.forEach(val => {
              console.log(val);
              val.children.forEach(lab => {
                if (lab.sonlist.length <= 0) {
                  // sonlist 中没有数据时
                  if (lab.timeValue != undefined) {
                    lab.timeValue.forEach(itm => {
                      if (itm.id == this.timeid) {
                        itm.value = this.datetime;
                      }
                    });
                  }
                } else {
                  // table的中的数据
                  lab.sonlist.forEach(ite => {
                    if (ite.timeValue != undefined) {
                      ite.timeValue.forEach(itms => {
                        if (itms.id == this.timeid) {
                          itms.value = this.datetime;
                        }
                      });
                    }
                  });
                }
              });
            });
          }
        }
      });
      this.show = false;
    },
    formatter(type, value) {
      if (type === "year") {
        return `${value}年`;
      } else if (type === "month") {
        return `${value}月`;
      }
      return value;
    }
  }
};
</script>
<style lang="less" scoped>
.muokuan {
  h2 {
    font-size: 0.62rem /* 26/50 */;
    margin-top: 1.18rem /* 59/50 */;
    margin-bottom: 0.46rem /* 23/50 */;
    margin-left: 0.6rem /* 30/50 */;
    color: #000;
    font-weight: 600;
  }

  /deep/.van-tabs__line {
    background-color: #408bea;
  }
  /deep/.van-field__control {
    border: 1px solid #aab2b8;
    // background-color: #eff3f6;
    padding-left: 0.4rem /* 40/100 */;
  }
  .inptext {
    margin-left: 0.5rem /* 25/50 */;
    padding-top: 0.2rem /* 10/50 */;
    color: #cccccc;
    font-size: 0.48rem /* 24/50 */;
  }
  .inptextarea {
    color: #646566;
    margin-left: 0.6rem /* 25/50 */;
    padding-top: 0.2rem /* 10/50 */;
  }
  /deep/.van-radio__icon {
    height: 1.58rem /* 79/50 */;
    width: 2.6rem /* 130/50 */;
    img {
      height: 1.58rem /* 79/50 */;
      width: 2.6rem /* 130/50 */;
    }
  }
  .van-radio {
    width: 2.6rem /* 130/50 */;
    position: relative;
    // float: left;
    margin-right: 0.3rem /* 25/50 */;
    margin-bottom: 0.5rem; /* 25/50 */
  }
  /deep/.van-radio__label {
    position: absolute;
    margin-left: 0;
    left: 50%;
    transform: translate(-50%);
    width: 100%;
    text-align: center;
  }
  .radios {
    // padding-left: 0.4rem /* 20/50 */;
    padding-top: 0.4rem /* 20/50 */;
    // padding-bottom: 0.4rem /* 20/50 */;
    background-color: #fff;
  }
  .van-radio-group {
    margin-left: 0.2rem;
    display: flex;
    /* 
        设置flex是否换行
          nowrap: 不换行(默认)
          wrap: 开启换行，子元素放不下了，就会换行
      */
    flex-wrap: wrap;
    /* 
        align-items: 调整侧轴的对齐方式（单行模式，不换行）
        align-content: 调整侧轴的对齐方式（多行的模式） 
          和justify-content一样： flex-start flex-end center space-around space-between
      */
    align-content: space-between;
    // justify-content: space-between;
  }
  .checkedbox {
    padding-top: 0.4rem /* 20/50 */;
    background-color: #fff;
    .bgbox {
      margin-bottom: 0.4rem /* 20/50 */;
    }
    .span1 {
      margin-left: 0.2rem /* 10/50 */;
      font-size: 0.52rem; /* 26/50 */
      margin-bottom: 0.4rem /* 20/50 */;
    }
    .span2 {
      font-size: 0.52rem /* 26/50 */;
      color: #408bea;
      background-color: #dfefff;
      margin-left: 0.2rem /* 10/50 */;
      margin-bottom: 0.4rem /* 20/50 */;
    }
  }
  .van-checkbox-group {
    margin-left: 0.2rem;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    .van-checkbox {
      width: 2.6rem /* 130/50 */;
      position: relative;
      margin-right: 0.3rem /* 25/50 */;
      margin-bottom: 0.5rem;
      /deep/.van-checkbox__icon {
        height: 1.58rem /* 79/50 */;
        width: 2.6rem /* 130/50 */;
        img {
          height: 1.58rem /* 79/50 */;
          width: 2.6rem /* 130/50 */;
        }
      }
      /deep/.van-checkbox__label {
        position: absolute;
        margin-left: 0;
        left: 50%;
        transform: translate(-50%);
        width: 100%;
        text-align: center;
      }
    }
  }
  .whether {
    background-color: #fff;
    padding-top: 1rem; /* 50/50 */
    position: relative;
    .van-radio:nth-child(2) {
      width: 100%;
      /deep/.van-radio__icon {
        width: 100%;
        img {
          width: 100%;
        }
      }
    }
    .van-cell {
      position: absolute;
      width: 80%;
      top: 0.8rem;
      left: 3rem;
    }
    .wher_ip {
      position: absolute;
      top: 1.4rem /* 50/100 */;
      left: 3rem /* 40/100 */;
    }
  }
  .Price_range {
    // padding-left: 0.4rem /* 20/50 */;
    padding-top: 0.8rem /* 40/50 */;
    padding-bottom: 0.4rem /* 20/50 */;
    background-color: #fff;
    .van-cell-group {
      display: flex;
      flex-wrap: wrap;
      align-content: space-between;
      line-height: 1.5rem /* 100/50 */;
      .van-cell {
        width: 44%;
      }
    }
  }
}
.top_input {
  padding-bottom: 0.4rem /* 10/50 */;
  .van-cell {
    width: 90%;
    // border: 1px solid #cccccc;
    margin-left: 0.4rem /* 20/50 */;
    margin-top: 0.2rem /* 10/50 */;
  }
}
.ele-radio {
  .el-radio-group {
    width: 100%;
    background-color: #fff;
    .el-radio {
      width: 100%;
      display: block;
      height: 1.64rem /* 82/50 */;
      border-bottom: 1px solid #e0e4ed;
      padding-top: 0.4rem /* 20/50 */;
      padding-left: 0.6rem /* 30/50 */;
      box-sizing: border-box;
    }
  }
}
.flooter {
  width: 80%;
  margin: 0 auto;
  margin-top: 1rem; /* 50/50 */
  margin-bottom: 1rem; /* 50/50 */
}
.Time_zone {
  p {
    font-size: 0.52rem /* 26/50 */;
    padding-top: 1.18rem /* 59/50 */;
    padding-bottom: 0.46rem /* 23/50 */;
    padding-left: 0.6rem /* 30/50 */;
    color: #8b919a;
    background-color: #fff;
  }
}
.wetm {
  padding-top: 0 !important; /* 50/50 */
  h2 {
    margin-top: 0.18rem;
  }
  /deep/.van-cell {
    top: 1.3rem !important;
  }
}
</style>

