<template>
  <div class="productdirector-audit">
    <div class="choice">
      <van-radio-group v-model="radio">
        <van-radio name='1' @click="changeAdopt">
        通过
        <img
          slot="icon"
          slot-scope="props"
          :src="props.checked ? icon.active : icon.normal"
        >
        </van-radio>
        <van-radio name='2' @click="changeReject">
          驳回
          <img
            slot="icon"
            slot-scope="props"
            :src="props.checked ? icon.active : icon.normal"
          >
        </van-radio>
      </van-radio-group>
    </div>
    <div class="adopt" v-show="isAdopt">
      <van-cell-group>
        <van-cell title="指派给（通过审核时需要选择）" />
        <van-cell :title="priority" is-link @click="Callout" />
        <van-field
          v-model="explain"
          rows="1"
          autosize
          label="说明（非必填）"
          type="textarea"
          placeholder="请输入文本"
        />
      </van-cell-group>
      <van-popup v-model="show" position="bottom" >
        <van-picker :columns="columns" show-toolbar @cancel="onCancel" @confirm="onConfirm" />
      </van-popup>
    </div>
    <div class="reject" v-show="isReject">
      <van-cell-group>
        <van-cell title="驳回至（驳回审核时需要选择）" />
        <el-radio-group v-model="radios">
          <el-radio :label="1">售前(项目需求等问题)</el-radio>
          <el-radio :label="2">营销主管（项目优先级等问题）</el-radio>
        </el-radio-group>
        <van-field
          v-model="explain"
          rows="1"
          autosize
          label="说明（必填）"
          type="textarea"
          placeholder="请输入文本"
        />
      </van-cell-group>
    </div>
    <div class="btn">
      <button class="draft-submit" @click="shenHe">提交</button>
      <button class="draft-submit" @click="toNews">返回列表</button>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      icon: {
        normal: require("../../assets/img/noative.png"),
        active: require("../../assets/img/ative.png")
      },
      radio: "1",
      radios: 1,
      show: false,
      columns: [],
      priority: "请选择产品经理", // 优先级
      explain: "",
      isAdopt: true,
      isReject: false,
      projectid: "",
      ProductList: [],
      ProductId: "",
      type: ""
    };
  },
  created() {
    this.projectid = localStorage.getItem("projectid");
    this.getProductList();
  },
  methods: {
    onConfirm(value, index) {
      console.log(value);
      this.priority = value;
      let newList = this.ProductList.filter(item => item.name == value);
      this.ProductId = newList[0].id;
      console.log(this.ProductId);
      this.show = false;
    },
    onCancel() {
      this.show = false;
    },
    Callout() {
      this.show = true;
    },
    changeAdopt() {
      this.isAdopt = true;
      this.isReject = false;
      this.explain = ''
    },
    changeReject() {
      this.isReject = true;
      this.isAdopt = false;
    },
    async getProductList() {
      const res = await this.axios.get("/api/frontend/product/list");
      console.log(res);
      if (res.status_code === 200) {
        this.ProductList = res.data;
        res.data.forEach(item => {
          this.columns.push(item.name);
        });
      }else{
        this.$toast(res.error);
      }
    },
    async shenHe() {
      if (this.radio === "1") {
        this.type = "psaAdoptPm";
      } else {
        this.ProductId = "";
        if (this.radios == "1") {
          this.type = "psaRejectPsd";
        } else {
          this.type = "psaRejectPss";
        }
        if(this.explain == ''){
          this.$toast('请填写驳回说明')
          return false
        }
      }
      const res = await this.axios.post(
        `/api/frontend/subject/${this.projectid}/operation/${this.type}`,
        {
          product_manager_id: this.ProductId,
          explain: this.explain
        }
      );
      console.log(res);
      if (res.status_code === 200) {
        this.$toast("提交成功");
        this.$router.push({ path: "/news" });
      } else {
        this.$toast(res.error);
      }
    },
    toNews() {
      this.$router.push({ path: "/news" });
    }
  }
};
</script>
<style lang="less" scoped>
@jianbian: linear-gradient(90deg, #09aaff, #2685ff);
.productdirector-audit {
  .el-radio-group {
    width: 92%;
  }
  .el-radio {
    display: block;
    margin-left: 0.6rem /* 30/50 */;
    margin-right: 0.6rem;
    padding-top: 0.4rem /* 20/50 */;
    padding-bottom: 0.4rem /* 20/50 */;
    border-bottom: 1px solid #ebedf0;
    width: 100%;
    box-sizing: border-box;
  }
  .btn {
    width: 100%;
    text-align: center;
    margin-top: 0.8rem /* 40/50 */;
    // background-color: #eff3f6;
    .draft-submit {
      width: 12.38rem /* 619/50 */;
      height: 1.7rem /* 85/50 */;
      background: @jianbian;
      border-radius: 43px;
      color: #fff;
      margin-bottom: 1rem;
      text-align: center;
    }
  }
  padding-bottom: 1rem /* 50/50 */;
  .van-radio-group {
    display: flex;
    padding-left: 0.6rem /* 30/50 */;
  }
  .van-radio {
    width: 6.6rem /* 130/50 */;
    position: relative;
    margin-right: 0.3rem /* 25/50 */;
    margin-bottom: 0.5rem; /* 25/50 */
    /deep/.van-radio__icon {
      height: 1.58rem /* 79/50 */;
      width: 6.6rem /* 130/50 */;
      img {
        height: 1.58rem /* 79/50 */;
        width: 6.6rem /* 130/50 */;
      }
    }
    /deep/.van-radio__label {
      position: absolute;
      margin-left: 0;
      left: 50%;
      transform: translate(-50%);
      width: 100%;
      text-align: center;
    }
  }
  .van-cell {
    color: #8b919a;
    /deep/.van-field__control {
      border: 1px solid #8b919a;
      height: 3rem /* 50/100 */ !important;
      padding-left: 0.5rem;
    }
    /deep/.van-field__label{
      width: 4rem;
    }
  }
}
</style>


