<template>
  <div>
    <phoneHander></phoneHander>
    <van-tabs v-model="active" @change="jurisdiction" :class="searchBarFixed === true ? 'isfixed' : ''"  ref="sheet">
      <van-tab title="项目预览"></van-tab>
      <van-tab title="项目状态"></van-tab>
      <van-tab title="操作"></van-tab>
    </van-tabs>
    
    <!-- 申请信息 -->
    <div class="preview-module">
      <!-- <div class="module" v-for="item in dataList" :key="item.id">
        <div class="module-title">{{item.text}}
          <van-icon name="edit" v-if="status == 'pre_sale_draft'" color='#408BEA' size='24px' @click='editform(item.id,item.type)'/>
        </div>
        <van-cell-group v-for="val in item.items_info">
          <van-cell :title="val.title + ':'" :value="val.value" />
        </van-cell-group>
      </div> -->
      <!-- 项目基本信息 -->
      <div class="module" v-if='basicList'>
        <div class="module-title">项目基本信息
          <van-icon name="edit" color='#408BEA' size='24px' v-if="status == 'pre_sale_draft' && isedit" @click='editform(basictype)' />
        </div>
        <van-cell-group>
          <van-cell title="项目名称" :value="basicList.entry_name" />
          <van-cell title="客户经理" :value="basicList.customers" />
          <van-cell title="所属行业" :value="basicList.industry " />
          <van-cell title="客户名称" :value="basicList.customer_name" />
          <van-cell title="项目背景" :value="basicList.scene" />
          <van-cell title="第三方合作" :value="basicList.cooperation" />
          <van-cell title="预估销售数量" :value="basicList.num" />
          <van-cell title="价格区间（单位：元)" :value="basicList.start_total + '-' + basicList.end_total " />
          <van-cell title="样机交付时间" :value="basicList.prototype_delivery_time" />
          <van-cell title="批量交付时间" :value="basicList.batch_delivery_time" />
        </van-cell-group>
      </div>
      <!-- 硬件配置需求 -->
      <div class="module" id="module1" v-if="hardwareList">
        <div class="module-title">硬件配置需求
          <van-icon name="edit" color='#408BEA' size='24px' v-if="status == 'pre_sale_draft' && isedit" @click='editform(hardwaretype)' />
        </div>
        <van-cell-group>
          <van-cell title="需求的产品品类" :value="hardwareList.demand_category_text"/>
          <van-cell title="认证需求" :value="hardwareList.confirm_demand"/>
          <van-cell v-if="hardwareList.specs" title="工作温湿度" :value="hardwareList.specs.work_humiture"/>
          <van-cell v-if="hardwareList.specs" title="储存温湿度" :value="hardwareList.specs.storage_humiture"/>
          <van-cell v-if="hardwareList.prevent != '不需要'" title="防水防尘" :value="'IP'+ hardwareList.prevent"/>
          <van-cell v-if="hardwareList.prevent == '不需要'" title="防水防尘" :value="hardwareList.prevent"/>
          <van-cell title="OP SHOCK指标" :value="hardwareList.OPS_HOCK"/>
          <van-cell title="OP-VIBRATION指标" :value="hardwareList.OP_VIBRATION"/>
          <van-cell title="输入电压" :value="hardwareList.voltage"/>
          <van-cell title="环境需求其它" :value="hardwareList.ambient_other"/>
          <van-cell title="尺寸需求" :value="hardwareList.size"/>
          <van-cell title="CPU" :value="hardwareList.cpu"/>
          <van-cell title="内存" :value="hardwareList.memory"/>
          <van-cell title="存储" :value="hardwareList.storage"/>
          <van-cell title="网口" :value="hardwareList.network"/>
          <van-cell title="串口" :value="hardwareList.sata"/>
          <van-cell title="并口" :value="hardwareList.parallel"/>
          <van-cell title="USB" :value="hardwareList.usb"/>
          <van-cell title="PCI" :value="hardwareList.pci"/>
          <van-cell title="PCIe" :value="hardwareList.pcie"/>
          <van-cell title="光驱" :value="hardwareList.cd_drive"/>
          <van-cell title="WIFI" :value="hardwareList.wifi"/>
          <van-cell title="Panel" :value="hardwareList.panel"/>
          <van-cell title="其它" :value="hardwareList.dispose_other"/>
        </van-cell-group>
      </div>
      <!-- 软件配置需求 X86-->
      <div class="module" v-if="X86List || istypeShow">
        <div class="module-title">软件配置需求X86
          <van-icon name="edit" color='#408BEA' size='24px' v-if="status == 'pre_sale_draft' && isedit" @click='editform(X86type)' />
        </div>
        <van-cell-group v-if="X86List">
          <van-cell title="操作系统版本" :value="X86List.edition" />
          <van-cell title="软件" :value="X86List.software" />
        </van-cell-group>
      </div>
      <!-- 软件配置需求 ARM-->
      <div class="module" v-if="ARMlist || istypeShow">
        <div class="module-title">软件配置需求ARM
          <van-icon name="edit" color='#408BEA' size='24px' v-if="status == 'pre_sale_draft' && isedit" @click='editform(ARMtype)' />
        </div>
        <van-cell-group v-if="ARMlist">
          <van-cell title="需要适配的OS版本" :value="ARMlist.edition " />
          <van-cell title="串口设备" :value="ARMlist.sata "/>
          <van-cell title="摄像头设备" :value="ARMlist.camera "/>
          <van-cell title="显示设备" :value="ARMlist.show "/>
          <van-cell title="需要特殊适配的设备其他" :value="ARMlist.special_other  "/>
          <van-cell title="UI定制需求" :value="ARMlist.ui"/>
          <van-cell title="固件升级方式" :value="ARMlist.firmware_text "/>
          <van-cell title="软件认证要求" :value="ARMlist.attestation_text "/>
          <van-cell title="需要软件屏蔽的功能" :value="ARMlist.shield"/>
          <van-cell title="其它" :value="ARMlist.arm_other "/>
        </van-cell-group>
      </div>
      <!-- 随机配件及资料 -->
      <div class="module" v-if="partslist">
        <div class="module-title">随机配件及资料
          <van-icon name="edit" color='#408BEA' size='24px' v-if="status == 'pre_sale_draft'" @click='editform(partstype)'/>
        </div>
        <van-cell-group>
          <van-cell title="资料" :value="partslist.material_text" />
          <van-cell title="线缆" :value="partslist.cable" />
          <van-cell title="挂架：" :value="partslist.pylons" />
          <van-cell title="键鼠" :value="partslist.mouse" />
          <van-cell title="其它需求" :value="partslist.other_needs" />
        </van-cell-group>
      </div>
      <!-- 品牌及服务 -->
      <div class="module" v-if="brandList" >
        <div class="module-title">品牌及服务
          <van-icon name="edit" color='#408BEA' size='24px' v-if="status == 'pre_sale_draft'" @click='editform(brandtype)'/>
        </div>
        <van-cell-group>
          <van-cell title="品牌需求" :value="brandList.brand_demand" />
          <van-cell title="年限" :value="brandList.year" />
          <van-cell title="服务模式" :value="brandList.pattern" />
        </van-cell-group>
      </div>
    </div>
    <!-- 项目状态 -->
    <div class="module-status" id="module2">
      <div class="status-title">项目状态</div>
      <!-- <i class="el-icon-exchange"></i> -->
      <el-timeline>
        <el-timeline-item
          placement='top'
          v-for="(activity, index) in activities"
          :key="index"
          :icon="activity.icon"
          :type="activity.type"
          :color="activity.color"
          :size="activity.size"
          :timestamp="activity.timestamp">
          <div class="status-information">
            <p class="ji-bie" v-if="activity.level_name">优先级 : {{activity.level_name}}</p>
            <p class="fang-an"  v-if="activity.programme">方案名称 : {{activity.programme}}</p>
            <template v-for="(imgsrc,index) in activity.url">
              <img :src="'http://iotapi.joinusad.com' + imgsrc" class="l_img" :key="index"  @click="lokBgimg(imgsrc)">
            </template>
            <p class="shuo-ming"  v-if="activity.explain">说明 : {{activity.explain}}</p>
            <p class="mi-ma"  v-if="activity.num">成单数量 : {{activity.num}}</p>
            <p class="mi-ma"  v-if="activity.completetime">成单日期 : {{activity.completetime}}</p>
          </div>
          <div class="status-agent ">{{activity.content}}</div>
        </el-timeline-item>
      </el-timeline>
    </div>
    <!-- 操作 -->
    <div class="user-operation" id="module3">
      <div class="operation-title">操作</div>
      <!--  && status == 'pre_sale_draft' -->
      <DraftStatus :status='status' :ps_id='ps_id' v-if="role == 'pre_sale' && status !== 'pre_sale_shelve' && status !== 'pre_sale_draft_confirm' && status !== 'pre_sale_draft_confirm_submit' && status !== 'pre_sale_confirm_programme'"></DraftStatus>
      <MarketingAudit v-if="role == 'pre_sale_supervisor' && status == 'pre_sale_supervisor_approval'"></MarketingAudit>
      <Productdirector v-if="role == 'product_supervisor' && status == 'product_supervisor_approval'"></Productdirector>
      <!-- 审核通过 -->
      <PresaleSee :status='status' v-if="role == 'pre_sale' && (status == 'pre_sale_draft_confirm' || status == 'pre_sale_draft_confirm_submit' || status == 'pre_sale_confirm_programme')"></PresaleSee>
      <Viewfeedback v-if="role == 'product_manager' && status == 'product_manager_approval'"></Viewfeedback>
      <!-- 后期加的功能 2021/4/13 -->
      <!-- <button class="Transfer_others" v-if="role == 'pre_sale'" @click="isShowBtn">移交他人</button>
      <van-cell-group v-if="showbtn" class="yijiao">
        <h2 class="text-title">请输入被移交人（售前）的邮箱</h2>
        <van-field v-model="mailbox" placeholder="邮箱" />
        <van-field v-model="reason" placeholder="请输入移交项目的理由" />
        <button class="determine" @click="changeTransferapi">确定移交</button>
      </van-cell-group> -->
    </div>
    <!-- 查看图片大图 -->
    <van-image-preview v-model="show" :images="images" @change="onChange" :closeable='true'>
      <!-- <template v-slot:index>第{{ index }}页</template> -->
    </van-image-preview>
  </div>
</template>

<script>
import phoneHander from "../components/phoneHander";
import DraftStatus from "../phone/userActions/draftstatus"; // 草稿状态下
import MarketingAudit from "./userActions/Marketingaudit"; // 营销主管审核
import Productdirector from "./userActions/Productdirector"; // 产品主管审核
import PresaleSee from "./userActions/PresaleSee"; //审核通过后的售前的操作
import Viewfeedback from "./userActions/Viewfeedback"; //产品经理反馈
export default {
  components: {
    phoneHander,
    DraftStatus, // 草稿状态下
    MarketingAudit, // 营销主管审核
    Productdirector, //产品主管审核
    PresaleSee, //售前擦看他人代办状态
    Viewfeedback
  },
  data() {
    return {
      active: 0,
      Pauthority: 0,
      searchBarFixed: false,
      activities: [],
      projectid: "",
      dataList: [],
      status: "",
      recordList: [],
      role: "",
      basicList: [],
      hardwareList: [],
      X86List: [],
      ARMlist: [],
      partslist: [],
      brandList: [],
      mailbox: "",
      reason: "",
      showbtn: false,
      show: false,
      index: 0,
      images: [],
      isedit: "",
      ps_id: "",
      basictype: "basic",
      hardwaretype: "hardware",
      X86type: "X86",
      ARMtype: "ARM",
      partstype: "parts",
      brandtype: "brand",
      istypeShow: false
    };
  },
  created() {
    this.projectid = localStorage.getItem("projectid");
    this.role = localStorage.getItem("role");
    console.log(this.role);
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
    this.getPreview();
  },
  methods: {
    lokBgimg(url) {
      this.images = [];
      this.show = true;
      // http://iotapi.joinusad.com
      this.images.push("http://iotapi.joinusad.com" + url);
    },
    onChange(index) {
      this.index = index;
    },
    isShowBtn() {
      this.showbtn = true;
    },
    async changeTransferapi() {
      const res = await this.axios.post(
        `/api/frontend/subject/${this.projectid}/operation/changeSubject`,
        {
          email: this.mailbox,
          explain: this.reason
        }
      );
      if (res.status_code === 200) {
        this.$toast("移交成功");
        this.$router.push({ path: "/news" });
      } else {
        this.$toast(res.error);
      }
    },
    // 预览数据
    async getPreview() {
      const res = await this.axios.get(
        `/api/frontend/project/preview/${this.projectid}`
      );
      if (res.status_code === 200) {
        console.log(res.data);
        res.data.data.items.forEach(item => {
          if (res.data.status == "pre_sale_draft") {
            item.status = "草稿";
          } else if (res.data.status == "pre_sale_supervisor_approval") {
            item.status = "提交审核";
          }
        });
        this.isedit = res.data.is_edit;
        console.log(this.isedit);
        this.status = res.data.data.status; // 项目状态
        this.ps_id = res.data.data.pre_sale_supervisor_id;
        console.log(this.status);
        this.dataList = res.data.data.items;
        if (this.dataList[0].itemable) {
          this.basicList = this.dataList[0].itemable;
          this.basicList.type = "basic";
        }
        if (this.dataList[1].itemable) {
          this.hardwareList = this.dataList[1].itemable;
          this.hardwareList.type = "hardware";
        }
        this.X86List = this.dataList[2].itemable;
        if (this.X86List) {
          this.X86List.type = "X86";
        }
        this.ARMlist = this.dataList[3].itemable;
        if (this.ARMlist) {
          this.ARMlist.type = "ARM";
        }
        if (this.dataList[4].itemable) {
          this.partslist = this.dataList[4].itemable;
          this.partslist.type = "parts";
        }
        if (this.dataList[5].itemable) {
          this.brandList = this.dataList[5].itemable;
          this.brandList.type = "brand";
        }
        this.recordList = res.data.data.logs;
        this.recordList.forEach(item => {
          this.activities.push({
            timestamp: item.updated_at,
            size: "large",
            type: item.event.type,
            icon: item.event.icon,
            color: item.event.color,
            content: item.content,
            level_name: item.feedback.level, // 优先级
            url: item.feedback.imgurl, // 网盘地址
            programme: item.feedback.programme, // 方案名称
            explain: item.feedback.explain, // 说明
            num: item.feedback.complete_num, // 成单数量
            completetime: item.feedback.complete_tine // 成单时间
          });
        });
        // console.log(this.ARMlist);
        // console.log(this.X86List);
        // let arr1 = Object.keys(this.X86List);
        // let arr2 = Object.keys(this.ARMlist);
        if (!this.X86List && !this.ARMlist) {
          this.istypeShow = true;
          console.log(this.istypeShow);
        }
      } else {
        this.$toast(res.error);
      }
    },
    // 去编辑
    editform(type) {
      console.log(type);
      localStorage.setItem("projectid", this.projectid);
      if (type == "basic") {
        this.$router.push({ path: "/basicinfo" });
      } else if (type == "hardware") {
        this.$router.push({ path: "/hcrequire" });
      } else if (type == "X86") {
        this.$router.push({ path: "/pbInfoMantion" });
      } else if (type == "ARM") {
        this.$router.push({ path: "/pbInfoMantion" });
      } else if (type == "parts") {
        this.$router.push({ path: "/randomaccessories" });
      } else if (type == "brand") {
        this.$router.push({ path: "/brandservice" });
      }
    },
    jurisdiction(index) {
      console.log(index);
      if (index === 0) {
        window.scrollTo("0", "0");
      } else if (index === 1) {
        document.querySelector("#module2").scrollIntoView(true);
      } else if (index === 2) {
        document.querySelector("#module3").scrollIntoView(true);
      }
    },
    handleScroll() {
      // tab吸顶
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // console.log(scrollTop); // 位置信息
      let isoffsetTop = this.$refs.sheet.offsetTop;
      if (scrollTop > isoffsetTop) {
        this.searchBarFixed = true;
      } else {
        this.searchBarFixed = false;
      }
    },
    beforeDestroy() {
      window.removeEventListener("scroll", this.handleScroll, false);
    }
  }
};
</script>


<style lang="less" scoped>
@sizsColor: #b4bac3;
@jianbian: linear-gradient(90deg, #09aaff, #2685ff);
/deep/.van-image-preview {
  .van-image-preview__close-icon {
    font-size: 1.5rem; /* 40/50 */
  }
}
.preview-module {
  /deep/.van-cell__value {
    word-wrap: break-word;
  }
}
/deep/.van-tab {
  background-color: #ebf0f5;
}
/deep/.van-tab--active {
  background-color: #fff;
}
/deep/.van-tabs__line {
  width: 5rem;
  background-color: #408bea;
}
.van-icon {
  position: absolute;
  top: 50%;
  right: 7%;
  transform: translate(0, -50%);
}
.module {
  border-bottom: 1px solid #d8dce4;
  box-sizing: border-box;
  .module-title {
    height: 1.36rem /* 68/50 */;
    background-color: #eff3f6;
    text-align: center;
    line-height: 1.36rem; /* 68/50 */
    position: relative;
    margin-top: 1rem; /* 50/50 */
    border-top: 1px solid #d8dce4;
    border-bottom: 1px solid #d8dce4;
    box-sizing: border-box;
  }
}
.module-status {
  background-color: #fff;
  margin-top: 0.4rem /* 20/50 */;
  .status-title {
    height: 1.36rem /* 68/50 */;
    background-color: #fff0e1;
    line-height: 1.36rem /* 68/50 */;
    text-align: center;
    color: #ff6600;
  }
  .el-timeline {
    margin-left: 0.6rem /* 30/50 */;
    margin-top: 0.98rem /* 49/50 */;
  }
  .status-information {
    color: @sizsColor;
    img {
      width: 50px;
    }
  }
  .status-agent {
    position: absolute;
    top: 0;
    right: 0.4rem /* 20/50 */;
    color: @sizsColor;
    text-align: right;
    width: 50%;
  }
}
.user-operation {
  background-color: #fff;
  margin-top: 0.4rem /* 20/50 */;
  position: relative;
  // overflow: hidden;
  .operation-title {
    height: 1.36rem /* 68/50 */;
    background-color: #fff0e1;
    line-height: 1.36rem /* 68/50 */;
    text-align: center;
    color: #ff6600;
    margin-bottom: 1rem /* 50/50 */;
  }
}
// tab吸顶
.isfixed {
  position: fixed;
  width: 100%;
  top: 0;
  margin-bottom: 1rem /* 50/50 */;
  z-index: 666;
}
.shuo-ming {
  width: 90%;
  word-wrap: break-word;
}
.Transfer_others {
  width: 60%;
  height: 40px;
  border-radius: 0.86rem /* 43/50 */;
  background: @jianbian;
  color: #fff;
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translate(-50%);
  margin-bottom: 40px;
}
.yijiao {
  // margin-left: 20px;
  margin-bottom: 20px;
  height: 180px;
  position: relative;
  /deep/.van-field__value {
    border: 1px solid #cccccc;
  }
}
.determine {
  width: 60%;
  height: 40px;
  border-radius: 0.86rem /* 43/50 */;
  background: @jianbian;
  color: #fff;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
}
</style>


