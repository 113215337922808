<template>
  <div class="basicinfo">
    <phoneHander></phoneHander>
    <div class="bgimage">
      <img src="../assets/img/dzbanner.png" alt="">
    </div>
    <h4>项目归属大区</h4>
    <van-cell-group>
      <van-cell title="归属大区" :value="regionName" />
    </van-cell-group>
    <h4>需求提交人</h4>
    <van-cell-group>
      <van-cell title="需求提交人" :value="positionName" />
    </van-cell-group>
    <!-- 项目名称 -->
    <div v-if="isName">
      <h4>项目名称</h4>
      <van-cell-group>
        <van-field v-model="projectName" label="项目名称" :error-message="error" placeholder="请输入项目名称"/>
      </van-cell-group>
      <p class="projec_text">XX客户XX项目需求</p>
    </div>
    <div v-if="isValue3">
      <h4>客户经理</h4>
      <van-cell-group>
        <van-field v-model="value3" label="客户经理" :error-message="error1" placeholder="请输入客户经理"/>
      </van-cell-group>
    </div>
    <div v-if="isValue4">
      <h4>所属行业</h4>
      <van-cell-group>
        <van-field v-model="value4" label="所属行业" :error-message="error2" placeholder="请输入所属行业"/>
      </van-cell-group>
    </div>
    <div v-if="isValue5">
      <h4>客户名称</h4>
      <van-cell-group>
        <van-field v-model="value5" label="客户名称" :error-message="error3" placeholder="请输入客户名称"/>
      </van-cell-group>
    </div>
    <!-- <div v-if="isValue6">
      <h4>渠道名称</h4>
      <van-cell-group>
        <van-field v-model="value6" label="渠道名称" :error-message="error4" placeholder="请输入渠道名称"/>
      </van-cell-group>
    </div> -->
    <div v-if="isValue7">
      <h4>项目背景</h4>
      <van-cell-group>
        <van-field
          v-model="value7"
          :error-message="error5"
          rows="3"
          autosize
          type="textarea"
          placeholder="请输入文本"
        />
      </van-cell-group>
    </div>
    <!-- 是否与第三方合作 -->
    <div v-if="isValue">
      <h4>项目是否需要和第三方合作</h4>
      <div class="whether clearfix"  >
        <van-radio-group v-model="checked">
          <van-radio  v-for="whether in cooperation" :key="whether" :name='whether' @click="changeCooperation(whether)">
            {{whether}}
            <img
              slot="icon"
              slot-scope="props"
              :src="props.checked ? icon.active : icon.normal"
              class="whethers"
            >
          </van-radio>
        </van-radio-group>
        <van-field v-model="value" :disabled='Isitdisabled'  placeholder="如果需要请输入内容" />
      </div>
    </div>
    <!-- 预估销售数量 -->
    <div v-if="isValue2">
      <h4>预估销售数量</h4>
      <div class="whether clearfix"  >
        <van-radio-group v-model="checked2">
          <van-radio  v-for="whether in estimate" :key="whether" :name='whether' @click="changeEstimate(whether)">
            {{whether}}
            <img
              slot="icon"
              slot-scope="props"
              :src="props.checked ? icon.active : icon.normal"
              class="whethers"
            >
          </van-radio>
        </van-radio-group>
        <van-field v-model="value2" type="digit" :disabled='Isitdisabled2'  placeholder="如果确定数量请输入内容" />
      </div>
    </div>
    <!-- 价格区间 -->
    <div v-if="isPrice">
      <h4>价格区间</h4>
      <div class="Price_range">
        <van-cell-group>
          <van-field type="digit"  v-model="Price"/>
          <span>至</span>
          <van-field type="digit" v-model="Price2" />
        </van-cell-group>
      </div>
    </div>
    <!-- 预期交货时间 -->
    <div v-if="isDate">
      <h4>预期交付时间</h4>
      <van-cell v-for="time in timeValue" :key="time.id" @click="changeTime(time)" :title="time.name" :value="time.value " clickable is-link />
    </div>
    <van-popup v-model="show" position="bottom" :overlay="true">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        confirm-button-text="确认选择"
        @confirm="tiemDetermine"
        :formatter="formatter"
      />
    </van-popup>
     <van-popup v-model="optionShow" position="bottom" :overlay="true" :style="{ height: '40%' }">
       <van-dropdown-menu>
        <van-dropdown-item v-model="value8" :options="option" @change='optiontime'/>
      </van-dropdown-menu>
    </van-popup>
    <van-popup v-model="optionShow2" position="bottom" :overlay="true" :style="{ height: '40%' }">
       <van-dropdown-menu>
        <van-dropdown-item v-model="value9" :options="option2" @change='optiontime2'/>
      </van-dropdown-menu>
    </van-popup>
    <div class="flooter">
      <van-button size="large" round type="info" @click="Submit">保存并下一步</van-button>
    </div>
  </div>
</template>
<script>
import phoneHander from "../components/phoneHander";
import fromCreates from "../components/create-form/createform";

export default {
  components: {
    phoneHander,
    fromCreates
  },
  data() {
    return {
      projectName: "",
      regionName: "",
      positionName: "",
      showList: [],
      // 自定义的radio的图标
      icon: {
        normal: require("../assets/img/noative.png"),
        active: require("../assets/img/ative.png")
      },
      cooperation: ["是", "否"], // 是否支持第三方合作
      checked: "", // 是否支持第三方合作
      value: "", // 是否支持第三方合作
      Isitdisabled: true, // 是否支持第三方合作 是否禁用输入
      estimate: ["确定数量", "不确定数量"], // 预估销售数量
      checked2: "", // 预估销售数量
      value2: "", // 预估销售数量
      Isitdisabled2: true, // 预估销售数量 是否禁用输入
      Price: "", // 价格区间
      Price2: "", // 价格区间
      timeValue: [
        { id: 1, value: "", name: "样机交付时间" },
        { id: 2, value: "", name: "批量交付时间" }
      ],
      show: false,
      currentDate: new Date(),
      timeId: "",
      error: "",
      error1: "",
      error2: "",
      error3: "",
      error4: "",
      error5: "",
      error6: "",
      error7: "",
      value3: "",
      value4: "",
      value5: "",
      value6: "",
      value7: "",
      value8: 3,
      value9: 3,
      isName: true,
      isValue: true,
      isValue2: true,
      isValue3: true,
      isValue4: true,
      isValue5: true,
      isValue6: true,
      isValue7: true,
      isPrice: true,
      isDate: true,
      option: [
        { text: '不需要样机', value: 0 },
        { text: '时间待定', value: 1 },
        { text: '选择交付时间', value: 2 },
        { text: '请选择', value: 3 },
      ],
      option2: [
        { text: '时间待定', value: 1 },
        { text: '选择交付时间', value: 2 },
        { text: '请选择', value: 3 },
      ],
      optionShow:false,
      optionShow2:false,
    };
  },
  created() {
    // this.projectid = localStorage.getItem("projectid");
    // getQueryValue
    this.getme();
    this.getShowAndHiden();
    // this.projectid = this.getQueryValue("id");
    this.projectid = localStorage.getItem("projectid");
    console.log(this.projectid);
    if (this.projectid) {
      this.getEditList();
    }
  },
  computed: {},
  methods: {
    Submit() {
      if (!this.projectName) {
        this.error = "请输入项目名称";
        this.$toast("请输入项目名称");
        return false;
      }
      if (!this.value3) {
        this.error1 = "请输入客户经理";
        this.$toast("请输入客户经理");
        return false;
      }
      if (!this.value4) {
        this.error2 = "请输入所属行业";
        this.$toast("请输入所属行业");
        return false;
      }
      if (!this.value5) {
        this.error3 = "请输入客户名称";
        this.$toast("请输入客户名称");
        return false;
      }
      if (!this.value7) {
        this.error5 = "请输入项目背景";
        this.$toast("请输入项目背景");
        return false;
      }
      if(!this.checked){
        this.$toast("请选择是否需要和第三方合作");
        return false
      }
      if(this.checked == '是' && this.value ==''){
        this.$toast("请输入第三方名称");
        return false
      }
      if(!this.checked2){
        this.$toast("请选择预估销售数量");
        return false
      }
      if(this.checked2 == '确定数量' && this.value2 == ''){
        this.$toast("请输入预估销售数量");
        return false
      }
      if (!this.Price || !this.Price2) {
        this.$toast("请输入价格区间");
        return false;
      }
      if(!this.timeValue[0].value){
        this.$toast("请选择样机交付时间");
        return false
      }
      if(!this.timeValue[1].value){
        this.$toast("请选择批量交付时间");
        return false
      }
      this.subject();
    },
    async subject() {
      const res = await this.axios.post(
        `api/frontend/subject/${this.projectid}/item/basic`,
        {
          entry_name: this.projectName,
          customers: this.value3,
          industry: this.value4,
          customer_name: this.value5,
          channel_name: this.value6,
          scene: this.value7,
          cooperation: this.value || "否",
          num: this.value2 || "不确定数量",
          start_total: this.Price,
          end_total: this.Price2,
          prototype_delivery_time: this.timeValue[0].value,
          batch_delivery_time: this.timeValue[1].value
        }
      );
      console.log(res);
      if (res.status_code === 200) {
        this.$router.push({ path: "/hcrequire" });
      }
    },
    async getEditList() {
      const res = await this.axios.get(
        `api/frontend/subject/${this.projectid}/item/basic`
      );
      console.log(res);
      if (res.status_code === 200) {
        if (res.data.itemable) {
          let list = res.data.itemable;
          this.projectName = list.entry_name;
          this.value3 = list.customers;
          this.value4 = list.industry;
          this.value5 = list.customer_name;
          this.value6 = list.channel_name;
          this.value7 = list.scene;
          if (list.cooperation !== "否") {
            this.checked = "是";
            this.Isitdisabled = false;
            this.value = list.cooperation;
          } else {
            this.checked = "否";
          }
          if (list.num !== "不确定数量") {
            this.checked2 = "确定数量";
            this.Isitdisabled2 = false;
            this.value2 = list.num;
          } else {
            this.checked2 = "不确定数量";
          }
          this.Price = list.start_total;
          this.Price2 = list.end_total;
          this.timeValue[0].value = list.prototype_delivery_time;
          this.timeValue[1].value = list.batch_delivery_time;
        }
      }
    },
    async getShowAndHiden() {
      const res = await this.axios.get(`/api/frontend/new/template/basic`);
      if (res.status_code === 200) {
        this.showList = res.data;
        console.log(this.showList);
        if (this.showList[0].is_show == 1) {
          this.isName = true;
        } else {
          this.isName = false;
        }
        if (this.showList[1].is_show == 1) {
          this.isValue3 = true;
        } else {
          this.isValue3 = false;
        }
        if (this.showList[2].is_show == 1) {
          this.isValue4 = true;
        } else {
          this.isValue4 = false;
        }
        if (this.showList[3].is_show == 1) {
          this.isValue5 = true;
        } else {
          this.isValue5 = false;
        }
        if (this.showList[4].is_show == 1) {
          this.isValue6 = true;
        } else {
          this.isValue6 = false;
        }
        if (this.showList[5].is_show == 1) {
          this.isValue7 = true;
        } else {
          this.isValue7 = false;
        }
        if (this.showList[6].is_show == 1) {
          this.isValue = true;
        } else {
          this.isValue = false;
        }
        if (this.showList[7].is_show == 1) {
          this.isValue2 = true;
        } else {
          this.isValue2 = false;
        }
        if (this.showList[8].is_show == 1) {
          this.isPrice = true;
        } else {
          this.isPrice = false;
        }
        if (this.showList[9].is_show == 1) {
          this.isDate = true;
        } else {
          this.isDate = false;
        }
      }
    },
    // 是否需要第三方合作
    changeCooperation(checked) {
      console.log(checked);
      if (checked === "是") {
        this.Isitdisabled = false;
      } else {
        this.Isitdisabled = true;
        this.value = "";
      }
    },
    // 预估销售数量
    changeEstimate(checked) {
      if (checked === "确定数量") {
        this.Isitdisabled2 = false;
      } else {
        this.Isitdisabled2 = true;
        this.value2 = "";
      }
    },
    // 时间选择
    changeTime(item) {
      // this.show = true;
      // console.log(item);
      if(item.name == '样机交付时间'){
        this.optionShow = true
      }else if(item.name == '批量交付时间'){
        this.optionShow2 = true
      }
      this.timeId = item.id;
    },
    optiontime(value){
      console.log(value);
      if(value == 2){
        this.optionShow = false
        this.show = true
      }else if(value != 3){
        let options = this.option.filter(item => item.value == value)
        this.timeValue[0].value = options[0].text
        this.optionShow = false
      }
    },
    optiontime2(value){
      console.log(value);
      if(value == 2){
        this.optionShow2 = false
        this.show = true
      }else if(value != 3){
        let options = this.option.filter(item => item.value == value)
        this.timeValue[1].value = options[0].text
        this.optionShow2 = false
      }
    },
    tiemDetermine(value) {
      let year = value.getFullYear();
      let month = value.getMonth() + 1; // 月份从0开始
      let day = value.getDate(); // 日期
      function addZero(n) {
        return n < 10 ? "0" + n : n;
      }
      let dateTime = year + "-" + addZero(month) + "-" + addZero(day);
      this.timeValue.forEach(item => {
        if (item.id == this.timeId) {
          item.value = dateTime;
        }
      });
      this.show = false;
    },
    formatter(type, value) {
      if (type === "year") {
        return `${value}年`;
      } else if (type === "month") {
        return `${value}月`;
      }
      return value;
    },
    // 获取职位和大区
    async getme() {
      const res = await this.axios("api/frontend/member/me");
      if (res.status_code === 200) {
        this.region_id = res.data.region_id;
        this.position_id = res.data.id;
        this.getRegion();
        this.getMember();
      }
    },
    // 获取项目归属大区
    async getRegion() {
      this.columns = [];
      const res = await this.axios.get("api/frontend/region");
      if (res.status_code === 200) {
        let newList = res.data.filter(item => item.id == this.region_id);
        this.regionName = newList[0].name;
      } else {
        this.$toast(res.error);
      }
    },
    // 获取售前工程师
    async getMember() {
      this.columns = [];
      const res = await this.axios.get("api/frontend/member");
      if (res.status_code === 200) {
        console.log(res);
        let newList = res.data.filter(item => item.id == this.position_id);
        this.positionName = newList[0].name;
        console.log(newList);
      } else {
        this.$toast(res.error);
      }
    },
    onCancel() {
      this.$toast("取消");
      this.show = false;
    },

    getQueryValue(name) {
      var url = window.location.href;
      var result = url.match(new RegExp("[?&]" + name + "=([^&]+)", "i"));

      if (result == null || result.length < 1) {
        return "";
      }
      return decodeURI(result[1]);
    }
  }
};
</script>

<style lang="less" scoped>
.basicinfo {
  /deep/.van-field__control {
    border: 1px solid #cccccc;
  }
  h4 {
    font-size: 0.62rem /* 26/50 */;
    margin-top: 1.18rem /* 59/50 */;
    margin-bottom: 0.46rem /* 23/50 */;
    margin-left: 0.6rem /* 30/50 */;
    color: #000;
    font-weight: 600;
  }
  /deep/.van-radio__icon {
    height: 1.58rem /* 79/50 */;
    width: 2.6rem /* 130/50 */;
    img {
      height: 1.58rem /* 79/50 */;
      width: 2.6rem /* 130/50 */;
    }
  }
  .van-radio {
    width: 2.6rem /* 130/50 */;
    position: relative;
    // float: left;
    margin-right: 0.3rem /* 25/50 */;
    margin-bottom: 0.5rem; /* 25/50 */
  }
  /deep/.van-radio__label {
    position: absolute;
    margin-left: 0;
    left: 50%;
    transform: translate(-50%);
    width: 100%;
    text-align: center;
  }
  .radios {
    // padding-left: 0.4rem /* 20/50 */;
    padding-top: 0.4rem /* 20/50 */;
    // padding-bottom: 0.4rem /* 20/50 */;
    background-color: #fff;
  }
  .van-radio-group {
    margin-left: 0.2rem;
    display: flex;
    /* 
        设置flex是否换行
          nowrap: 不换行(默认)
          wrap: 开启换行，子元素放不下了，就会换行
      */
    flex-wrap: wrap;
    /* 
        align-items: 调整侧轴的对齐方式（单行模式，不换行）
        align-content: 调整侧轴的对齐方式（多行的模式） 
          和justify-content一样： flex-start flex-end center space-around space-between
      */
    align-content: space-between;
    // justify-content: space-between;
  }
  .checkedbox {
    padding-top: 0.4rem /* 20/50 */;
    background-color: #fff;
    .bgbox {
      margin-bottom: 0.4rem /* 20/50 */;
    }
    .span1 {
      margin-left: 0.2rem /* 10/50 */;
      font-size: 0.52rem; /* 26/50 */
      margin-bottom: 0.4rem /* 20/50 */;
    }
    .span2 {
      font-size: 0.52rem /* 26/50 */;
      color: #408bea;
      background-color: #dfefff;
      margin-left: 0.2rem /* 10/50 */;
      margin-bottom: 0.4rem /* 20/50 */;
    }
  }
  .van-checkbox-group {
    margin-left: 0.2rem;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    .van-checkbox {
      width: 2.6rem /* 130/50 */;
      position: relative;
      margin-right: 0.3rem /* 25/50 */;
      margin-bottom: 0.5rem;
      /deep/.van-checkbox__icon {
        height: 1.58rem /* 79/50 */;
        width: 2.6rem /* 130/50 */;
        img {
          height: 1.58rem /* 79/50 */;
          width: 2.6rem /* 130/50 */;
        }
      }
      /deep/.van-checkbox__label {
        position: absolute;
        margin-left: 0;
        left: 50%;
        transform: translate(-50%);
        width: 100%;
        text-align: center;
      }
    }
  }
  .whether {
    background-color: #fff;
    padding-top: 1rem; /* 50/50 */
    position: relative;
    .van-radio:nth-child(2) {
      width: 100%;
      /deep/.van-radio__icon {
        width: 100%;
        img {
          width: 100%;
        }
      }
    }
    .van-cell {
      position: absolute;
      width: 80%;
      top: 0.8rem;
      left: 3rem;
    }
    .wher_ip {
      position: absolute;
      top: 1.4rem /* 50/100 */;
      left: 3rem /* 40/100 */;
    }
  }
  .Price_range {
    // padding-left: 0.4rem /* 20/50 */;
    padding-top: 0.8rem /* 40/50 */;
    padding-bottom: 0.4rem /* 20/50 */;
    background-color: #fff;
    .van-cell-group {
      display: flex;
      flex-wrap: wrap;
      align-content: space-between;
      line-height: 1.5rem /* 100/50 */;
      .van-cell {
        width: 44%;
      }
    }
  }
  .Time_zone {
    p {
      font-size: 0.52rem /* 26/50 */;
      padding-top: 1.18rem /* 59/50 */;
      padding-bottom: 0.46rem /* 23/50 */;
      padding-left: 0.6rem /* 30/50 */;
      color: #8b919a;
      background-color: #fff;
    }
  }
}
.flooter {
  width: 80%;
  margin: 0 auto;
  margin-top: 1rem; /* 50/50 */
  margin-bottom: 1rem; /* 50/50 */
}
.projec_text {
  margin-left: 0.6rem; /* 10/100 */
  margin-top: 0.2rem; /* 20/100 */
  font-size: 0.42rem; /* 22/100 */
  color: #aab2b8;
}
</style>
