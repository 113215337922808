<template>
  <div class="management">
    <!-- <phoneHander></phoneHander> -->
    <div class="screen">
      <el-select v-model="value" placeholder="请选择" @change='select'>
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
          >
        </el-option>
      </el-select>
    </div>
    <el-table :data="tableData" stripe style="width: 100%" >
    <el-table-column prop="name" label="姓名" >
    </el-table-column>
    <el-table-column prop="date" label="注册时间" >
    </el-table-column>
    <el-table-column prop="state" label="状态" >
    </el-table-column>
    <el-table-column  label="管理" >
      <template v-slot:default="{row}">
        <van-button type="info" size="mini" v-if="row.state == '未审核'" @click="approval(row.id)">批准</van-button>
        <van-button type="warning" size="mini" v-if="row.state == '未审核'" @click="reject(row.id)">驳回</van-button>
        <van-button type="danger" size="mini" v-if="row.state == '已审核'" @click="Disable(row.id)">禁用</van-button>
        <van-button type="primary" size="mini" v-if="row.state == '已禁用'" @click="Enable(row.id)">启用</van-button>
      </template>
    </el-table-column>
  </el-table>
  </div>
</template>

<script>
import phoneHander from "../components/phoneHander";

export default {
  components: {
    phoneHander
  },
  data() {
    return {
      options: [
        {
          value: "0",
          label: "未审核"
        },
        {
          value: "1",
          label: "已审核"
        },
        {
          value: "2",
          label: "已驳回"
        },
        {
          value: "3",
          label: "已禁用"
        }
      ],
      value: "",
      tableData: [
        // {
        //   date: "2016-05-02",
        //   name: "王小虎",
        //   state: "未审核"
        // }
      ]
    };
  },
  created() {
    this.getmemberList();
  },
  methods: {
    async getmemberList() {
      this.tableData = [];
      const res = await this.axios.get("/api/frontend/member");
      console.log(res);
      if (res.status_code === 200) {
        res.data.forEach(item => {
          let state = "";
          if (item.is_reviewed == 0) {
            state = "未审核";
          } else if (item.is_reviewed == 1) {
            state = "已审核";
          } else if (item.is_reviewed == 2) {
            state = "已驳回";
          } else if (item.is_reviewed == 3) {
            state = "已禁用";
          }
          this.tableData.push({
            name: item.name,
            date: item.updated_at,
            state: state,
            id: item.id,
            is_reviewed: item.is_reviewed
          });
        });
      }
    },
    async approval(id) {
      console.log(id);
      const res = await this.axios.post("api/frontend/member/reviewed", {
        id: id,
        is_reviewed: 1
      });
      if (res.status_code === 200) {
        this.$toast("修改成功");
        this.getmemberList();
      } else {
        this.$toast(res.error);
      }
      console.log(res);
    },
    async Disable(id) {
      const res = await this.axios.post("api/frontend/member/reviewed", {
        id: id,
        is_reviewed: 3
      });
      if (res.status_code === 200) {
        this.$toast("修改成功");
        this.getmemberList();
      } else {
        this.$toast(res.error);
      }
    },
    async reject(id) {
      const res = await this.axios.post("api/frontend/member/reviewed", {
        id: id,
        is_reviewed: 2
      });
      if (res.status_code === 200) {
        this.$toast("修改成功");
        this.getmemberList();
      } else {
        this.$toast(res.error);
      }
    },
    async Enable(id) {
      const res = await this.axios.post("api/frontend/member/reviewed", {
        id: id,
        is_reviewed: 1
      });
      if (res.status_code === 200) {
        this.$toast("修改成功");
        this.getmemberList();
      } else {
        this.$toast(res.error);
      }
    },
    async select() {
      console.log(this.value);
      const res = await this.axios.get(
        `/api/frontend/member?status=${this.value}`
      );
      console.log(res);
      if (res.status_code === 200) {
        this.tableData = [];
        res.data.forEach(item => {
          let state = "";
          if (item.is_reviewed == 0) {
            state = "未审核";
          } else if (item.is_reviewed == 1) {
            state = "已审核";
          } else if (item.is_reviewed == 2) {
            state = "已驳回";
          } else if (item.is_reviewed == 3) {
            state = "已禁用";
          }
          this.tableData.push({
            name: item.name,
            date: item.updated_at,
            state: state,
            id: item.id,
            is_reviewed: item.is_reviewed
          });
        });
      }
    }
  }
};
</script>
<style lang="less" scoped>
.management {
  .screen {
    width: 100%;
    height: 2.38rem /* 119/50 */;
    background-color: #d8dce4;
    text-align: center;
    .el-select {
      width: 90%;
      margin-top: 0.4rem /* 20/50 */;
      /deep/.el-input {
        .el-input__inner {
          height: 1.6rem /* 80/50 */;
        }
      }
    }
  }
  /deep/.el-table--enable-row-transition {
    .el-table__body {
      td {
        padding: 0;
        height: 1.58rem /* 79/50 */;
      }
    }
  }
  /deep/.el-table_1_column_1 {
    .cell {
      color: #0099ff;
    }
  }
}
</style>


