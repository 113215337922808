<template>
  <div class="project-list">
    <phoneHander></phoneHander>
    <van-tabs v-model="active" @click="changeActive">
      <van-tab title="全部"></van-tab>
      <van-tab title="我的待办"></van-tab>
      <van-tab title="他人待办"></van-tab>
      <van-tab title="已结束"></van-tab>
    </van-tabs>
    <div class="screen">
      <h2>申请时间</h2>
      <div class="xuan-ze">
        <el-date-picker v-model="value1" type="date" placeholder="选择日期"></el-date-picker>
        至
        <el-date-picker v-model="value2" type="date" placeholder="选择日期"></el-date-picker>
      </div>
      <div class="search">
        <input type="text" v-model="value3" placeholder="请输入客户或项目名称关键词">
        <button @click="search">搜索</button>
      </div>
    </div>
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <div class="list" v-for="item in list" :key="item.id">
        <van-cell-group>
          <van-cell :title=" '客户：' + item.name" :value=" '编号：' + item.number" />
          <van-cell title="项目名称" :value='item.title' />
          <van-cell title="归属大区" :value="item.region" />
          <van-cell title="预估销售数量" :value="item.num" />
          <van-cell title="硬件产品品类" :value="item.pinlei" />
          <van-cell title="软件类型" :value="item.rjlx" />
          <van-cell title="申请时间" :value="item.created_at" />
          <van-cell title="当前状态" value="草稿" v-if="item.status == 'pre_sale_draft' && item.pre_sale_supervisor_id" />
          <van-cell title="当前状态" value="草稿(被驳回)" v-if="item.status == 'pre_sale_draft' && !item.pre_sale_supervisor_id" />
          <van-cell title="当前状态" value="营销主管审核中" v-if="item.status == 'pre_sale_supervisor_approval'" />
          <van-cell title="当前状态" value="产品主管审核中" v-if="item.status == 'product_supervisor_approval'" />
          <van-cell title="当前状态" value="产品经理创建方案中" v-if="item.status == 'product_manager_approval'" />
          <van-cell title="当前状态" value="方案确认中" v-if="item.status == 'pre_sale_draft_confirm' || item.status == 'pre_sale_draft_confirm_submit' || item.status == 'pre_sale_confirm_programme'" />
          <van-cell title="当前状态" value="成单" v-if="item.status == 'pre_sale_confirm_complete'" />
          <van-cell title="当前状态" value="搁置" v-if="item.status == 'pre_sale_shelve'" />
          <button @click="edit(item.id)" v-if="item.status == 'pre_sale_draft' && role == 'pre_sale'">编辑</button>
          <!-- v-if="item.status !== 'pre_sale_draft'" -->
          <button @click="see(item.id)" v-if="item.is_show">查看</button>
          <button @click="del(item.id)" class="btn_del" v-if="item.status == 'pre_sale_draft' && item.pre_sale_supervisor_id && role == 'pre_sale'">删除</button>

        </van-cell-group>
      </div>
    </van-list>
    <!-- <div class="list">
      <van-cell-group>
        <van-cell title="某某某客户名称" value="编号：20201126002" />
        <van-cell title="XXX项目名称"  />
        <van-cell title="归属大区" value="华东" />
        <van-cell title="预估销售数量" value="XXX" />
        <van-cell title="硬件产品品类" value="ECI" />
        <van-cell title="软件类型" value="X86" />
        <van-cell title="申请时间" value="2020-10-20 13:46:12" />
        <van-cell title="当前状态" value="草稿" />
        <button>编辑</button>
      </van-cell-group>
    </div> -->
  </div>
</template>

<script>
import phoneHander from "../components/phoneHander";

export default {
  components: {
    phoneHander
  },
  data() {
    return {
      active: 0, // 0 全部  1我的代办  2他人代办  3已结束
      value1: "",
      value2: "",
      value3: "",
      list: [],
      loading: false,
      finished: false,
      page: 0,
      Category: "all",
      role:''
    };
  },
  created() {
    this.onLoad();
    // this.getProject();
    this.role = localStorage.getItem('role')
    console.log(this.role);
  },
  methods: {
    changeActive(index) {
      console.log(index);
      this.page = 1;
      this.list = [];
      if (index == 1) {
        this.Category = "my";
        this.getProject();
      } else if (index == 2) {
        this.Category = "other";
        this.getProject();
      } else if (index == 3) {
        this.Category = "complete";
        this.getProject();
      } else if (index == 0) {
        this.Category = "all";
        this.getProject();
      }
    },
    // api/frontend/project
    async getProject() {
      // this.list = [];
      const res = await this.axios.get(
        `api/frontend/project?status=${this.Category}&page=${this.page}`
      );
      console.log(res);
      if (res.status_code === 200) {
        let countList = res.data;
        this.loading = false;
        if (countList.length == 0) {
          this.finished = true;
        }
        this.list = this.list.concat(countList);
        console.log(this.list);
      }
    },
    onLoad() {
      console.log("加载");
      this.page++;
      this.loading = true;
      this.getProject();
    },
    edit(id) {
      localStorage.setItem("projectid", id);
      this.$router.push({ path: "/CustomApplication" });
    },
    see(id) {
      localStorage.setItem("projectid", id);
      this.$router.push({ path: "/preview" });
    },
    // 删除
    del(id) {
      this.$dialog
        .confirm({
          message: "是否删除此项目？"
        })
        .then(() => {
          this.delApi(id);
        })
        .catch(() => {});
    },
    async delApi(id) {
      const res = await this.axios.delete(`/api/frontend/project/${id}`);
      console.log(res);
      if (res.status_code === 200) {
        this.$toast("删除成功");
        this.page = 0;
        this.list = [];
        this.onLoad();
      } else {
        this.$toast(res.error);
      }
    },
    // 搜索
    async search() {
      // if (this.value1 == "" && this.value2 == "" && this.value3 == "") {
      //   this.$toast("请输入搜索条件");
      //   return false;
      // }
      if ((this.value1 && this.value2) || this.value3) {
        let start_time = this.moment(this.value1).format("YYYY-MM-DD");
        let end_time = this.moment(this.value2).format("YYYY-MM-DD");
        const res = await this.axios.get(
          `api/frontend/project?status=${
            this.Category
          }&start_time=${start_time}&end_time=${end_time}&name=${this.value3}`
        );
        console.log(res);
        if (res.status_code === 200) {
          this.list = res.data;
        }
      } else {
        this.$toast("请输入搜索条件");
      }
    }
  }
};
</script>
<style lang="less" scoped>
.project-list {
  /deep/.van-tab {
    background-color: #ebf0f5;
  }
  /deep/.van-tab--active {
    background-color: #fff;
  }
  /deep/.van-tabs__line {
    width: 25%;
    background-color: #408bea;
  }
  .screen {
    width: 100%;
    height: 6.14rem /* 307/50 */;
    background-color: #fff;
    padding-left: 0.6rem /* 30/50 */;
    padding-top: 0.66rem /* 33/50 */;
    box-sizing: border-box;
    h2 {
      font-size: 0.52rem /* 26/50 */;
      color: #8b919a;
    }
    .xuan-ze {
      margin-top: 0.4rem /* 20/50 */;
      .el-date-editor {
        width: 45%;
      }
    }
    .search {
      margin-top: 0.6rem /* 30/50 */;
      input {
        width: 10.5rem /* 525/50 */;
        height: 1.58rem /* 79/50 */;
        border: 1px solid #d8dce4;
      }
      button {
        margin-left: 0.3rem /* 15/50 */;
        width: 2.9rem /* 145/50 */;
        height: 1.58rem /* 79/50 */;
        background: linear-gradient(90deg, #09aaff, #2685ff);
        color: #fff;
      }
    }
  }
  .list {
    margin-top: 0.4rem /* 20/50 */;
    padding-bottom: 0.4rem /* 20/50 */;
    background-color: #fff;
    button {
      width: 13.8rem /* 690/50 */;
      height: 1.58rem /* 79/50 */;
      border-radius: 0.06rem /* 3/50 */;
      background: linear-gradient(90deg, #09aaff, #2685ff);
      color: #fff;
      margin-left: 0.6rem /* 30/50 */;
      margin-bottom: 0.1rem; /* 10/100 */
    }
    .btn_del {
      width: 13.8rem /* 690/50 */;
      height: 1.58rem /* 79/50 */;
      border-radius: 0.06rem /* 3/50 */;
      background: linear-gradient(90deg, #f12f79, #ec1c84);
      color: #fff;
      margin-left: 0.6rem /* 30/50 */;
      margin-bottom: 0.1rem; /* 10/100 */
    }
  }
  .van-cell:first-child {
    .van-cell__value {
      color: #09aaff;
    }
  }
  .van-cell:nth-child(8) {
    .van-cell__value {
      color: #09aaff;
    }
    .van-cell__title {
      color: #2487ff;
    }
  }
}
</style>


