<template>
  <div>
    <div class="handertop">
      <van-icon name="arrow-left" size='20px' @click="$router.back(-1)" />
      <div>{{handerText}}</div>
      <van-icon name="user-o"  size='20px' @click="$router.push({ path:'/userinformation'})" />
    </div>
    <div class="hander">
      <van-icon name="wap-nav" size='24px' @click="drawer = true"/>
    </div>
    <el-drawer
      title="我是标题"
      :visible.sync="drawer"
      :with-header="false"
      size='50%'
      style="z-index:600;">
      <div>
        <i class="el-icon-close" @click="drawer = false"></i>
      </div>
      <ul>
        <li v-if="is_show" @click="explain">开始定制</li>
        <!-- <li @click="CustomApplication">定制申请</li> -->
        <li @click="projectlist">申请列表</li>
        <li @click="news">消息提醒</li>
        <li @click="userinformation">账号信息</li>
        <!-- <li v-if="show"  @click="clickManage">售前账号管理</li> -->
        <!-- <li  @click="change">选择职位</li> -->
      </ul>
    </el-drawer>
  </div>
</template>


<script>
export default {
  data() {
    return {
      handerText: "",
      drawer: false,
      show: false,
      token: "",
      is_show: false,
      is_reviewed: ""
    };
  },
  props: ["updata"],
  created() {
    console.log(this.updata);
    if (this.$route.path == "/CustomApplication") {
      this.handerText = "定制申请";
    } else if (this.$route.path == "/explain") {
      this.handerText = "定制说明";
    } else if (this.$route.path == "/basicinfo") {
      this.handerText = "项目基本信息";
    } else if (this.$route.path == "/pbInfoMantion") {
      this.handerText = "软件配置需求";
    } else if (this.$route.path == "/hcrequire") {
      this.handerText = "硬件配置需求";
    } else if (this.$route.path == "/randomaccessories") {
      this.handerText = "随机配件及资料";
    } else if (this.$route.path == "/brandservice") {
      this.handerText = "品牌及服务";
    } else if (this.$route.path == "/preview") {
      this.handerText = "项目预览";
    } else if (this.$route.path == "/register") {
      if (this.updata == 1) {
        this.handerText = "账号修改";
      } else {
        this.handerText = "账号注册";
      }
    } else if (this.$route.path == "/userinformation") {
      this.handerText = "账号信息";
    } else if (this.$route.path == "/news") {
      this.handerText = "消息提醒";
    } else if (this.$route.path == "/projectlist") {
      this.handerText = "申请列表";
    }
    // console.log(this.$route.path);
    // console.log(this.handerText);
  },
  mounted() {
    this.token = localStorage.getItem("token");
    this.openid = localStorage.getItem("openid");
    if (this.token && this.openid) {
      this.getuser();
      this.getme();
    }
  },
  methods: {
    change() {
      this.$router.push({ path: "/login" });
    },
    explain() {
      if (this.is_reviewed == 1) {
        this.$router.push({ path: "/explain?getinto=1" });
      } else {
        this.$toast("账号未审核");
      }
    },
    CustomApplication() {
      if (this.is_reviewed == 1) {
        this.$router.push({ path: "/CustomApplication" });
      } else {
        this.$toast("账号未审核");
      }
    },
    projectlist() {
      if (this.is_reviewed == 1) {
        this.$router.push({ path: "/projectlist" });
      } else {
        this.$toast("账号未审核");
      }
    },
    userinformation() {
      this.$router.push({ path: "/userinformation" });
    },
    news() {
      if (this.is_reviewed == 1) {
        this.$router.push({ path: "/news" });
      } else {
        this.$toast("账号未审核");
      }
    },
    async getuser() {
      const res = await this.axios.get("/api/frontend/member/isPreSale");
      if (res.status_code === 200) {
        this.show = res.data.pre_sale_supervisor;
        this.is_show = res.data.pre_sale;
      } else {
        // this.$toast(res.error);
      }
    },
    async getme() {
      const res = await this.axios("api/frontend/member/me");
      if (res.status_code === 200) {
        this.is_reviewed = res.data.is_reviewed;
      }
    },
    clickManage() {
      if (this.is_reviewed == 1) {
        this.$router.push({ path: "/management" });
      } else {
        this.$toast("账号未审核");
      }
    }
  }
};
</script>


<style lang="less" scoped>
@import "../assets/css/base.css";
.handertop {
  height: 1.8rem /* 90/50 */;
  background-color: #000;
  display: flex;
  .van-icon {
    width: 2rem; /* 100/50 */
    text-align: center;
    line-height: 1.8rem; /* 90/50 */
    color: #fff;
  }
  div {
    flex: 1;
    text-align: center;
    line-height: 1.8rem; /* 90/50 */
    color: #fff;
    font-size: 0.62rem; /* 31/50 */
  }
}
.hander {
  height: 1.8rem /* 90/50 */;
  background-color: #408bea;
  padding-right: 0.68rem; /* 34/50 */
  text-align: right;
  color: #fff;
  .van-icon {
    line-height: 1.8rem /* 90/50 */;
  }
}
/deep/.el-drawer__body {
  ul {
    li {
      height: 1.8rem /* 90/50 */;
      border-bottom: 1px solid #e6e5e5;
      font-size: 0.52rem /* 26/50 */;
      text-align: center;
      line-height: 1.8rem; /* 90/50 */
    }
  }
  div {
    height: 1.78rem /* 89/50 */;
    // float: right;
    padding-right: 0.4rem /* 20/50 */;
    padding-top: 0.4rem /* 20/50 */;
    i {
      float: right;
    }
  }
}
</style>

