<template>
  <div class="pbinfo">
    <phoneHander></phoneHander>
    <div class="bgimage">
      <img src="../assets/img/dzbanner.png" alt="">
    </div>
    <h4>选择定制类型（二选一）</h4>
    <van-tabs v-model="active" @click="onClick">
      <van-tab title="X86定制" class="x86">
        <van-cell-group>
          <p>操作系统名称及版本</p>
          <van-field v-model="value1" label=""  />
          <p>软件</p>
          <van-field v-model="value2" rows="3" type="textarea"  />
        </van-cell-group>
        <div class="flooter">
          <van-button size="large" round type="info" @click="SubmitX86">保存并下一步</van-button>
        </div>
      </van-tab>
      <van-tab title="ARM定制" class="arm">
        <div>
          <h4>需要适配的OS版本</h4>
          <van-cell-group>
            <van-field v-model="value3" :error-message="error" />
          </van-cell-group>
        </div>
        <div>
          <h4>需要适配的硬件设备</h4>
          <van-cell-group>
            <van-field v-model="value4" label='串口设备' :error-message="error1"  />
            <van-field v-model="value5" label='摄像头设备' :error-message="error2" />
            <van-field v-model="value6" label='显示设备' :error-message="error3"/>
            <van-field v-model="value7" label='其他' rows="3" type="textarea" :error-message="error4" />
          </van-cell-group>
        </div>
        <div>
          <h4>需要集成的软件及服务</h4>
          <van-cell-group>
            <van-field v-model="value8" rows="3" type="textarea" :error-message="error5" />
          </van-cell-group>
        </div>
        <div>
          <h4>UI定制需求</h4>
          <van-cell-group>
            <van-field v-model="value9" rows="3" type="textarea" :error-message="error6" />
          </van-cell-group>
        </div>
        <div>
          <h4>固件升级方式</h4>
          <van-cell-group>
            <div class="checkedbox">
              <div class="bgbox">
                <span class="span1">已选:</span>
                <span class="span2" v-for="isSelect in result" :key="isSelect">{{isSelect}}</span>
              </div>
              <van-checkbox-group v-model="result">
                <van-checkbox
                  v-for="checkbox in list"
                  :key="checkbox"
                  :name="checkbox"
                  @click="changeCheck(result)"
                >
                {{checkbox}}
                  <img
                    slot="icon"
                    slot-scope="props"
                    :src="props.checked ? icon.active : icon.normal"
                  >
                </van-checkbox>
              </van-checkbox-group>
              <van-field v-model="value10" v-if="show"  placeholder="如果选中其他请输入" />
            </div>
          </van-cell-group>
        </div>
        <div>
          <h4>软件认证要求</h4>
          <van-cell-group>
            <div class="checkedbox">
              <div class="bgbox">
                <span class="span1">已选:</span>
                <span class="span2" v-for="isSelect in result2" :key="isSelect">{{isSelect}}</span>
              </div>
              <van-checkbox-group v-model="result2">
                <van-checkbox
                  v-for="checkbox in list2"
                  :key="checkbox"
                  :name="checkbox"
                  @click="SoftwareClick(result2,checkbox)"
                >
                {{checkbox}}
                  <img
                    slot="icon"
                    slot-scope="props"
                    :src="props.checked ? icon.active : icon.normal"
                  >
                </van-checkbox>
              </van-checkbox-group>
              <van-field v-model="value11" v-if="show2"  placeholder="如果选中其他请输入" />
            </div>
          </van-cell-group>
        </div>
        <div>
          <h4>需要软件屏蔽的功能</h4>
          <van-cell-group>
            <van-field v-model="value12" :error-message="error7" />
          </van-cell-group>
        </div>
        <div>
          <h4>其它</h4>
          <van-cell-group>
            <van-field v-model="value13" rows="3" type="textarea" :error-message="error8" />
          </van-cell-group>
        </div>
        <div class="flooter">
          <van-button size="large" round type="info" @click="SubmitArm">保存并下一步</van-button>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
import phoneHander from "../components/phoneHander";
import fromCreates from "../components/create-form/createform";
import { text } from "body-parser";
export default {
  components: {
    phoneHander,
    fromCreates
  },
  data() {
    return {
      // 自定义的radio的图标
      icon: {
        normal: require("../assets/img/noative.png"),
        active: require("../assets/img/ative.png")
      },
      active: 0,
      projectid: "",
      index: 0,
      is_status: "",
      result: [], // 固件
      list: ["线刷", "U盘", "TF卡", "OTA升级", "本地OTA", "其它"], // 固件
      show: false, // 固件
      result2: [], // 软件认证需求
      list2: ["微信认证", "支付宝认证", "其它", "不需要"], // 软件认证需求
      show2: false, // 软件认证需求
      idlist: "",
      value1: "Windows 7 professional",
      value2: "不需要",
      value3: "Android10", // 需要适配的OS版本
      value4: "不需要", // 串口设备
      value5: "不需要", // 摄像头设备
      value6: "不需要", // 显示设备
      value7: "不需要", // 其他
      value8: "不需要", // 需要集成的软件及服务
      value9: "不需要", // UI定制需求
      value10: "", // 固件升级方式
      value11: "", // 软件认证要求
      value12: "不需要", // 需要软件屏蔽的功能
      value13: "不需要", // 其他
      error: "",
      error1: "",
      error2: "",
      error3: "",
      error4: "",
      error5: "",
      error6: "",
      error7: "",
      error8: ""
    };
  },
  created() {
    this.projectid = localStorage.getItem("projectid");
    if (this.projectid) {
      this.getEditList();
    }
  },
  computed: {},
  methods: {
    async getEditList() {
      const res = await this.axios.get(
        `api/frontend/subject/${this.projectid}/item/X86`
      );
      if (res.status_code === 200) {
        if (res.data.itemable) {
          this.active = 0;
          this.value1 = res.data.itemable.edition;
          this.value2 = res.data.itemable.software;
        } else {
          this.getEditListARM();
        }
      }
    },
    async getEditListARM() {
      const res = await this.axios.get(
        `api/frontend/subject/${this.projectid}/item/ARM`
      );
      console.log(res);
      if (res.status_code === 200) {
        if (res.data.itemable) {
          let list = res.data.itemable;
          this.active = 1;
          this.value3 = list.edition;
          this.value4 = list.sata;
          this.value5 = list.camera;
          this.value6 = list.show;
          this.value7 = list.special_other;
          this.value8 = list.software_services;
          this.value9 = list.ui;
          this.result = list.firmware.checked;
          if (list.firmware.checked.indexOf("其它") !== -1) {
              this.show = true;
              this.value10 = list.firmware.value;
            }
          this.result2 = list.attestation.checked;
          if (list.attestation.checked.indexOf("其它") !== -1) {
            this.show2 = true;
            this.value11 = list.attestation.value;
          }
          this.value12 = list.shield;
          this.value13 = list.arm_other;
        }
      }
    },
    SubmitX86() {
      if (!this.value1) {
        this.$toast("请输入操作系统名称及版本");
        return false;
      }
      if (!this.value2) {
        this.$toast("请输入软件");
        return false;
      }
      this.subjectX86();
    },
    SubmitArm() {
      if (!this.value3) {
        this.$toast("请输入需要适配的OS版本");
        this.error = "请输入需要适配的OS版本";
        return false;
      }
      if (!this.value4) {
        this.$toast("请输入串口设备");
        this.error1 = "请输入串口设备";
        return false;
      }
      if (!this.value5) {
        this.$toast("请输入摄像头设备");
        this.error2 = "请输入摄像头设备";
        return false;
      }
      if (!this.value6) {
        this.$toast("请输入显示设备");
        this.error3 = "请输入显示设备";
        return false;
      }
      if (!this.value7) {
        this.$toast("请输入其他");
        this.error4 = "请输入其他";
        return false;
      }
      if (!this.value7) {
        this.$toast("请输入其他");
        this.error4 = "请输入其他";
        return false;
      }
      if (!this.value8) {
        this.$toast("请输入需要集成的软件及服务");
        this.error5 = "请输入需要集成的软件及服务";
        return false;
      }
      if (!this.value9) {
        this.$toast("请输入UI定制需求");
        this.error6 = "请输入UI定制需求";
        return false;
      }
      if (this.result.length < 1) {
        this.$toast("请选择固件升级方式");
        return false;
      } else if (this.result.indexOf("其它") !== -1) {
        if (!this.value10) {
          this.$toast("请输入固件的其它升级方式");
          return false;
        }
      }
      if (this.result2.length < 1) {
        this.$toast("请选择软件认证要求");
        return false
      } else if (this.result2.indexOf("其它") !== -1) {
        if (!this.value11) {
          this.$toast("请输入软件认证要求");
          return false
        }
      }
      if (!this.value12) {
        this.$toast("请输入需要软件屏蔽的功能");
        this.error7 = "请输入需要软件屏蔽的功能";
        return false
      }
      if (!this.value13) {
        this.$toast("请输入其它");
        this.error8 = "请输入其它";
        return false
      }
      this.subjectArm();
    },
    async subjectX86() {
      const res = await this.axios.post(
        `api/frontend/subject/${this.projectid}/item/X86`,
        {
          edition: this.value1,
          software: this.value2
        }
      );
      console.log(res);
      if (res.status_code === 200) {
        this.$router.push({ path: "/randomaccessories" });
      }
    },
    async subjectArm() {
      const res = await this.axios.post(
        `api/frontend/subject/${this.projectid}/item/ARM`,
        {
          edition: this.value3,
          sata: this.value4,
          camera: this.value5,
          show: this.value6,
          special_other: this.value7,
          software_services: this.value8,
          ui: this.value9,
          firmware: {
            checked: this.result,
            value: this.value10
          },
          attestation: {
            checked: this.result2,
            value: this.value11
          },
          shield: this.value12,
          arm_other: this.value13
        }
      );
      console.log(res);
      if (res.status_code === 200) {
        this.$router.push({ path: "/randomaccessories" });
      }
    },
    onClick(index, title) {
      this.index = index;
      if (index == 0) {
      } else if (index == 1) {
      }
    },
    SoftwareClick(list, checkbox) {
      if (list.indexOf("其它") !== -1) {
        this.show2 = true;
      } else {
        this.show2 = false;
        this.value11 = "";
      }
      if (checkbox === "不需要") {
        this.result2 = [];
        this.result2.push("不需要");
        this.show2 = false;
        this.value11 = "";
      } else {
        this.remove("不需要");
      }
    },
    remove(val) {
      let index = this.result2.indexOf(val);
      if (index > -1) {
        this.result2.splice(index, 1);
      }
    },
    changeCheck(list) {
      if (list.indexOf("其它") !== -1) {
        this.show = true;
      } else {
        this.show = false;
        this.value10 = "";
      }
    },
    getQueryValue(name) {
      var url = window.location.href;
      var result = url.match(new RegExp("[?&]" + name + "=([^&]+)", "i"));

      if (result == null || result.length < 1) {
        return "";
      }
      return decodeURI(result[1]);
    }
  }
};
</script>

<style lang="less" scoped>
.pbinfo {
  .x86 {
    padding-top: 0.4rem /* 20/50 */;
    background-color: #fff;
    p {
      margin-left: 1rem; /* 10/50 */
      margin-bottom: 0.4rem;
      margin-top: 0.4rem;
    }
    .van-cell {
      width: 85%;
      border: 1px solid #dde5eb;
      margin-left: 1rem; /* 50/50 */
    }
  }
  .arm {
    h4 {
      font-size: 0.62rem /* 26/50 */;
      margin-top: 1.18rem /* 59/50 */;
      margin-bottom: 0.46rem /* 23/50 */;
      margin-left: 0.6rem /* 30/50 */;
      color: #000;
      font-weight: 600;
    }
    .van-cell {
      /deep/.van-field__value {
        border: 1px solid #dde5eb;
      }
    }
    .checkedbox {
      padding-top: 0.4rem /* 20/50 */;
      background-color: #fff;
      .bgbox {
        margin-bottom: 0.4rem /* 20/50 */;
      }
      .span1 {
        margin-left: 0.2rem /* 10/50 */;
        font-size: 0.52rem; /* 26/50 */
        margin-bottom: 0.4rem /* 20/50 */;
      }
      .span2 {
        font-size: 0.52rem /* 26/50 */;
        color: #408bea;
        background-color: #dfefff;
        margin-left: 0.2rem /* 10/50 */;
        margin-bottom: 0.4rem /* 20/50 */;
      }
    }
    .van-checkbox-group {
      margin-left: 0.2rem;
      display: flex;
      flex-wrap: wrap;
      align-content: space-between;
      .van-checkbox {
        width: 2.6rem /* 130/50 */;
        position: relative;
        margin-right: 0.3rem /* 25/50 */;
        margin-bottom: 0.5rem;
        /deep/.van-checkbox__icon {
          height: 1.58rem /* 79/50 */;
          width: 2.6rem /* 130/50 */;
          img {
            height: 1.58rem /* 79/50 */;
            width: 2.6rem /* 130/50 */;
          }
        }
        /deep/.van-checkbox__label {
          position: absolute;
          margin-left: 0;
          left: 50%;
          transform: translate(-50%);
          width: 100%;
          text-align: center;
        }
      }
    }
  }
  h4 {
    font-size: 0.4rem /* 26/100 */;
    color: #8b919a;
    margin-top: 0.5rem /* 20/100 */;
    margin-left: 0.45rem /* 15/100 */;
    margin-bottom: 0.3rem /* 30/100 */;
  }
  /deep/.van-tabs__line {
    background-color: #408bea;
    width: 50%;
    top: 0;
  }
  .flooter {
    width: 80%;
    margin: 0 auto;
    margin-top: 1rem; /* 50/50 */
    margin-bottom: 1rem; /* 50/50 */
  }
}
</style>
