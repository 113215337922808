<template>
  <div class="news">
    <phoneHander></phoneHander>
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <div class="newlist" v-for="item in newList" :key="item.id">
        <van-cell-group >
          <van-cell :title="item.name == null || item.name == '' ? '暂无数据' : item.name" :value="'编号：' + item.number" />
          <van-cell :title="item.title == null || item.title == '' ? '暂无数据' : item.title"  />
          <van-cell :title="item.content.name" :value="item.content.time" />
          <van-cell title="查看详情" is-link @click='Viewdetails(item.id,item.subject_id)' />
        </van-cell-group>
      </div>
    </van-list>
    <!-- <div class="newlist">
      <van-cell-group>
        <van-cell title="某某某客户名称" value="编号：20201126002" />
        <van-cell title="某某项目名称"  />
        <van-cell title="产品经理提交方案" value="2020-10-20 13:14" />
        <van-cell title="查看详情" is-link />
      </van-cell-group>
    </div> -->
  </div>
</template>

<script>
import phoneHander from "../components/phoneHander";

export default {
  components: {
    phoneHander
  },
  data() {
    return {
      newList: [],
      page: 0,
      loading: false,
      finished: false
    };
  },
  created() {
    this.onLoad();
  },
  methods: {
    async getnews() {
      const res = await this.axios.get(`/api/frontend/news?page=${this.page}`);
      if (res.status_code === 200) {
        let countList = res.data.data;
        this.loading = false;
        if (countList.length == 0) {
          this.finished = true;
        }
        this.newList = this.newList.concat(countList);
        console.log(this.newList);
      }
    },
    Viewdetails(id, subject_id) {
      console.log(id, subject_id);
      localStorage.setItem("projectid", subject_id);
      this.$router.push({ path: "/preview" });
    },
    onLoad() {
      this.page++;
      this.loading = true;
      this.getnews();
    }
  }
};
</script>

<style lang="less" scoped>
.news {
  .van-cell:first-child {
    .van-cell__value {
      color: #09aaff;
    }
  }
  .newlist {
    margin-bottom: 0.4rem /* 20/50 */;
  }
  .van-cell:nth-child(4) {
    .van-cell__title {
      color: #2487ff;
    }
  }
  .van-cell__title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>


